.tab-img{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .submit{
    display: flex !important;
    justify-content: center !important;
    margin-left: 80px !important;
    background-color: #f0552b !important;
    border: none;
    color: #fff !important;
  }
  .submit:hover{
    background-color: #f0562b86 !important;
  }
  .title{
   color:  #f0552b;
  }

  .login_bg{
    background-image: radial-gradient(circle at 67% 83%, rgba(245,245,245, 0.14) 0%, rgba(245,245,245, 0.14) 1%,transparent 1%, transparent 5%,transparent 5%, transparent 100%),radial-gradient(circle at 24% 80%, rgba(245,245,245, 0.14) 0%, rgba(245,245,245, 0.14) 27%,transparent 27%, transparent 63%,transparent 63%, transparent 100%),radial-gradient(circle at 23% 5%, rgba(245,245,245, 0.14) 0%, rgba(245,245,245, 0.14) 26%,transparent 26%, transparent 82%,transparent 82%, transparent 100%),radial-gradient(circle at 21% 11%, rgba(245,245,245, 0.14) 0%, rgba(245,245,245, 0.14) 35%,transparent 35%, transparent 45%,transparent 45%, transparent 100%),radial-gradient(circle at 10% 11%, rgba(245,245,245, 0.14) 0%, rgba(245,245,245, 0.14) 21%,transparent 21%, transparent 81%,transparent 81%, transparent 100%),radial-gradient(circle at 19% 61%, rgba(245,245,245, 0.14) 0%, rgba(245,245,245, 0.14) 20%,transparent 20%, transparent 61%,transparent 61%, transparent 100%),radial-gradient(circle at 13% 77%, rgba(245,245,245, 0.14) 0%, rgba(245,245,245, 0.14) 63%,transparent 63%, transparent 72%,transparent 72%, transparent 100%),radial-gradient(circle at 30% 93%, rgba(245,245,245, 0.14) 0%, rgba(245,245,245, 0.14) 33%,transparent 33%, transparent 82%,transparent 82%, transparent 100%),linear-gradient(90deg, rgba(23,22,207, 0.32),rgba(7,18,215, 0.57));}