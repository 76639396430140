
/* @import url("../../assets/css/animate.css");
@import url("../../assets/css/bootstrap.min.css");
@import url("../../assets/css/fonts.min.css"); */

                                            /*Google Fonts*/
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,400i,500,500i,600,600i,700,700i,800');

*:focus {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; 
}
* {
  outline: none !important;
}

/*   Typography    */

body {
  min-height: 100vh;
  font-family: 'Poppins', sans-serif; 
  position: relative;
  font-size: 14px;
  color: #313131; 
  letter-spacing: 0.5px;
  
}
img {
  height: auto;
  max-width: 100%;
  border: none;
  outline: none;
    -webkit-transition: all 0.4s ease 0s;
       -moz-transition: all 0.4s ease 0s;
        -ms-transition: all 0.4s ease 0s;
         -o-transition: all 0.4s ease 0s;
            transition: all 0.4s ease 0s;
}
a, a:focus, a:active, a:link, img {
  outline: none;
  text-decoration: none;
}

a {
  color: #ff3030; 
  text-decoration: none !important;
      -webkit-transition: all 0.25s ease 0s;
         -moz-transition: all 0.25s ease 0s;
          -ms-transition: all 0.25s ease 0s;
           -o-transition: all 0.25s ease 0s;
              transition: all 0.25s ease 0s;
}
  a:hover { color: #ff3030; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { line-height: 1.4; color: #1b2839; }
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a { color: inherit; }

h1, .h1 { font-size: 1.75rem; }
h2, .h2 { font-size: 1.5rem; }
h3, .h3 { font-size: 1.25rem; }
h4, .h4 { font-size: 1.125rem; }
h5, .h5 { font-size: 1rem; }
h6, .h6 { font-size: 0.875rem; }

p {
  font-size: 14px;
  line-height: 1.82;
  margin-bottom: 0;
  word-break: break-word; 
}

small, .small { font-size: 13px; }

b, .b, strong, .strong { font-weight: 600; }
.page-pretitle {
  letter-spacing: 0.5px;
  text-transform: uppercase; 
}
.page-title {
  font-size: 23px;
  font-weight: 600;
  color: #444444;
  line-height: 30px;
  margin-bottom: 20px; 
}
.page-category {
  color: #444444;
  line-height: 1.8;
  margin-bottom: 25px; 
}
.text-primary, .text-primary a {
  color: #ff3030 !important; 
}
  .text-primary:hover, .text-primary a:hover {
    color: #ff3030 !important; 
  }

.text-secondary, .text-secondary a {
  color: #0071e8 !important; 
}
  .text-secondary:hover, .text-secondary a:hover {
    color: #0071e8 !important; 
  }

.text-info, .text-info a {
  color: #48abf7 !important; 
}
  .text-info:hover, .text-info a:hover {
    color: #48abf7 !important; 
  }

.text-success, .text-success a {
  color: #1bb73f !important; 
}
  .text-success:hover, .text-success a:hover {
    color: #1bb73f !important; 
  }

.text-warning, .text-warning a {
  color: #ff9d21 !important; 
}
  .text-warning:hover, .text-warning a:hover {
    color: #ff9d21 !important; 
  }

.text-danger, .text-danger a {
  color: #dc3545 !important; 
}
  .text-danger:hover, .text-danger a:hover {
    color: #dc3545 !important; 
  }

label {
  color: #495057 !important;
  font-size: 14px !important; 
}

.text-small { font-size: 11px; }

.metric-value {
  margin-bottom: 5px;
  line-height: 1;
  white-space: nowrap; 
}
.metric-label {
  font-size: .975rem;
  font-weight: 500;
  color: #686f76;
  white-space: nowrap;
  margin-bottom: 0; 
}
/*   Background   */
.bg-dark { background-color: #1b2839 !important; }
.bg-primary { background-color: #ff3030 !important; }
.bg-secondary { background-color: #0071e8 !important; }
.bg-info { background-color: #48abf7 !important; }
.bg-success { background-color: #1bb73f !important; }
.bg-warning { background-color: #ff9d21 !important; }
.bg-danger { background-color: #dc3545 !important; }
.bg-grey { background: #f9fbfd !important; }

.bg-light-primary { background-color: #ff3030 !important; }
.bg-light-secondary { background-color: #007cff !important; }
.bg-light-info { background-color: #7400ff !important; }
.bg-light-danger { background-color: #ff4800 !important; }

/*      Wrapper      */
.pull-right {float: right; }
.pull-left {float: left; }

.wrapper {
  /* min-height: 100vh; */
  position: relative;
  top: 0;
  height: 10vh;
  margin-left: 250px;
  
}
.main-header {
  background: #ffffff;
  min-height: 60px;
  width: 100%;
  position: fixed;
  z-index: 1001;
  box-shadow: 0px 0px 5px rgba(18, 23, 39, 0.5); 
}
.main-header .navbar-header {
  min-height: 62px; 
  margin-left: 250px;
  
}
.main-header .navbar-header .btn-toggle {
  margin-right: 30px;
  margin-left: 20px; 
}

.logo-header {
  background:darkslateblue;
/* background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 15%, rgba(123,10,148,1) 100%); */
  float: left;
  width: 250px;
  
  line-height: 60px;
  color: #333333;
  z-index: 1001;
  font-size: 17px;
  font-weight: 400;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  z-index: 1001;
  display: flex;
  align-items: center;
  position: relative;
  transition: all .3s; 
  justify-content: space-between;
}
.logo-header .big-logo { margin-right: 8px; }
.logo-header .big-logo:hover { text-decoration: none; }
.logo-header .big-logo .logo-img {
  width: 35px;
  height: 35px; 
}
.logo-header .logo {
  color: #666666;
  opacity: 1;
  position: relative;
  height: 100%; 
}
.logo-header .logo:hover { text-decoration: none; }
.logo-header .logo .navbar-brand {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 0px; 
}
.logo-header .navbar-toggler {
  padding-left: 0px;
  padding-right: 0px;
  opacity: 0;
  display: none; 
}
.logo-header .navbar-toggler .navbar-toggler-icon {
  height: 1em;
  width: 1em;
  color: #0a0909;
  font-size: 22px; 
}
.logo-header .more {
  background: transparent;
  border: 0;
  font-size: 22px;
  padding: 0;
  opacity: 0;
  width: 0;
  display: none; 
}

.btn-toggle {
  font-size: 20px !important;
  line-height: 20px;
  padding: 0px !important;
  background: transparent !important;
  color: #ffffff !important; 
}
.btn-toggle:hover, .btn-toggle:focus { opacity: 1; }

#search-nav {
  flex: 1;
  max-width: 400px; 
}
.sidebar .nav > .nav-item.active > a:before {
  opacity: 1 !important;
  position: absolute;
  z-index: 1;
  width: 3px;
  height: 100%;
  content: '';
  left: 0;
  top: 0; 
}
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  
  margin-top: 0px;
  display: block;
  z-index: 1000;
  color: #ffffff;
  font-weight: 200;
  background: #ffffff;
  -webkit-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  -moz-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  transition: all .3s; 
}
.sidebar.dark-bg {
    background:darkslateblue;
    
}
.sidebar.full-height { margin-top: 0; }
.sidebar .user{
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 12.5px;
  border-bottom: 1px solid #f1f1f1;
  display: block;
  margin-left: 10px;
  margin-right: 10px; 
}
.sidebar .user .info a {
  white-space: nowrap;
  display: block;
  position: relative; 
}
.sidebar .user .info a:hover, 
.sidebar .user .info a:focus {
  text-decoration: none; 
}
.sidebar .user .info a > span{
  font-size: 14px;
  font-weight: 400;
  color: #777;
  display: flex;
  flex-direction: column; 
}
.sidebar .user .info a > span .user-level {
  color: #555;
  font-weight: 600;
  font-size: 12px;
  margin-top: 5px; 
}
.sidebar .user .info a .link-collapse { padding: 7px 0; }
.sidebar .user .info .caret {
  position: absolute;
  top: 17px;
  right: 0px;
  border-top-color: #777; 
}
.sidebar .sidebar-wrapper {
  position: relative;
  max-height: calc(100vh - 75px);
  min-height: 100%;
  overflow: auto;
  width: 100%;
  z-index: 4;
  padding-bottom: 100px;
  transition: all .3s; 
}
.sidebar .sidebar-wrapper .sidebar-content {
  padding-top: 0px;
  padding-bottom: 55px; 
  overflow: hidden; 
}
.sidebar .sidebar-wrapper .scroll-element.scroll-y { top: 5px !important; }
.sidebar .nav {
  display: block;
  float: none;
  margin-top: 20px; 
}
.sidebar .nav .nav-section { margin: 15px 0 0 0; }
.sidebar .nav .nav-section .sidebar-mini-icon{
  text-align: center;
  font-size: 15px;
  color: #909093;
  display: none; 
}
.sidebar .nav .nav-section .text-section{
  padding: 2px 30px;
  font-size: 12px;
  color: #727275;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
  margin-top: 20px; 
}
.sidebar .nav > .nav-item{  display: list-item; }
.sidebar .nav > .nav-item.active > a { background: rgba(199, 199, 199, 0.2) !important; color: #ffffff !important; }
.sidebar .nav > .nav-item.active > a:before { background: #ff3030 !important; }
.sidebar .nav > .nav-item.active:hover > a:before { background: #ff3030 !important; }
.sidebar .nav > .nav-item.active a i { color: #ff3030 !important; }
.sidebar .nav > .nav-item:hover > a {
    background: rgba(199,199,199,0.2) !important;
    color: #fff !important;
}
.sidebar .nav > .nav-item.submenu > li > a i { color: rgba(23, 125, 255, 0.76); }
.sidebar .nav > .nav-item a {
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 8px 25px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  position: relative; 
}
.sidebar .nav > .nav-item a:hover, .sidebar .nav > .nav-item a:focus { text-decoration: none; color: #b17777; }
.sidebar .nav > .nav-item a .letter-icon{
  color: #a1a2a6;
  margin-right: 15px;
  width: 25px;
  text-align: center;
  vertical-align: middle;
  float: left;
  font-size: 20px;
  font-weight: 200; 
}
.sidebar .nav > .nav-item a i {
  margin-right: 15px;
  width: 25px;
  text-align: center;
  vertical-align: middle;
  float: left;
  font-size: 18px;
  line-height: 30px; 
}
.sidebar .nav > .nav-item a i[class^="flaticon-"] { font-size: 20px; }
.sidebar .nav > .nav-item a p {
  font-size: 14px;
  margin-bottom: 0px;
  margin-right: 5px;
  white-space: nowrap;
  letter-spacing: 0.5px
}
.sidebar .nav > .nav-item a .caret {
  margin-left: auto;
  margin-right: 10px;
  transition: all .5s;
}
.sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] .caret {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg); 
}
.sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true]:before { background: #ff3030; }
.sidebar .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #ff3030 !important; 
}
.sidebar .nav.nav-primary > .nav-item.active a:before {
  background: #ff3030 !important; 
}
.sidebar .nav.nav-primary > .nav-item.active a i {
  color: #ffffff !important; 
}
.sidebar .nav.nav-secondary > .nav-item a:hover i, .sidebar .nav.nav-secondary > .nav-item a:focus i, 
.sidebar .nav.nav-secondary > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #0071e8 !important; 
}
.sidebar .nav.nav-secondary > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #0071e8 !important; 
}
.sidebar .nav.nav-secondary > .nav-item.active a:before {
  background: #0071e8 !important; 
}
.sidebar .nav.nav-secondary > .nav-item.active a i {
  color: #0071e8 !important; 
}
.sidebar .nav.nav-info > .nav-item a:hover i, .sidebar .nav.nav-info > .nav-item a:focus i, 
.sidebar .nav.nav-info > .nav-item a[data-toggle=collapse][aria-expanded=true] i{
  color: #48abf7 !important; 
}
.sidebar .nav.nav-info > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #48abf7 !important; 
}
.sidebar .nav.nav-info > .nav-item.active a:before {
  background: #48abf7 !important; 
}
.sidebar .nav.nav-info > .nav-item.active a i {
  color: #48abf7 !important; 
}
.sidebar .nav.nav-success > .nav-item a:hover i, .sidebar .nav.nav-success > .nav-item a:focus i, 
.sidebar .nav.nav-success > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #1bb73f !important; 
}
.sidebar .nav.nav-success > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #1bb73f !important; 
}
.sidebar .nav.nav-success > .nav-item.active a:before {
  background: #1bb73f !important; 
}
.sidebar .nav.nav-success > .nav-item.active a i {
  color: #1bb73f !important; 
}
.sidebar .nav.nav-warning > .nav-item a:hover i, .sidebar .nav.nav-warning > .nav-item a:focus i, 
.sidebar .nav.nav-warning > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #ff9d21 !important; 
}
.sidebar .nav.nav-warning > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #ff9d21 !important; 
}
.sidebar .nav.nav-warning > .nav-item.active a:before {
  background: #ff9d21 !important; 
}
.sidebar .nav.nav-warning > .nav-item.active a i {
  color: #ff9d21 !important; 
}
.sidebar .nav.nav-danger > .nav-item a:hover i, .sidebar .nav.nav-danger > .nav-item a:focus i, 
.sidebar .nav.nav-danger > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #dc3545 !important; 
}
.sidebar .nav.nav-danger > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #dc3545 !important; 
}
.sidebar .nav.nav-danger > .nav-item.active a:before {
  background: #dc3545 !important; 
}
.sidebar .nav.nav-danger > .nav-item.active a i {
  color: #dc3545 !important; 
}
.sidebar .nav-collapse {
  margin-top: 0px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  padding-top: 10px; 
}
.sidebar .nav-collapse li a:before, .sidebar .nav-collapse li a:hover:before { opacity: 0 !important; }
.sidebar .nav-collapse li a {
  margin-bottom: 3px !important;
  padding: 10px 25px !important; 
}
.sidebar .nav-collapse li a .sub-item {
  font-size: 14px;
  position: relative;
  margin-left: 25px;
  opacity: 0.90;
  letter-spacing: 0; 
}
.sidebar .nav-collapse li a .sub-item:before {
  content: '';
  height: 4px;
  width: 4px;
  background: rgba(131, 132, 138, 0.89);
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%; 
}
.sidebar .nav-collapse li a:hover .sub-item { opacity: 1; }
.sidebar .nav-collapse li.active a .sub-item { color: #ffffff; }
.sidebar .nav-collapse li a .sidebar-mini-icon {
  font-size: 18px;
  color: #C3C5CA;
  margin-right: 15px;
  width: 25px;
  text-align: center;
  vertical-align: middle;
  float: left;
  font-weight: 300 !important; 
}
.sidebar .nav-collapse.subnav {
  padding-bottom: 10px;
  margin-bottom: 0px; 
}
.sidebar .nav-collapse.subnav li a { padding-left: 40px !important; }
.main-panel {
  position: relative;
  width: calc(100% - 250px);
  height:fit-content;
  margin-top: 55px;
  min-height: 100%;
  float: right;
  transition: all .3s; 
  background-color: #f9f8f8;
}                        
.main-panel > .content {
  padding: 0px !important;
  min-height: calc(100% - 123px);
  margin-top: 62px;
  overflow: hidden; 
}
.main-panel > .content-full {
  padding: 0px !important;
  min-height: calc(100% - 123px);
  margin-top: 63px;
  overflow: hidden; 
}
.main-panel .page-header {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding-left: 450px;
}
.main-panel .page-header .page-title {
  margin-bottom: 0px; 
}
.main-panel .page-header .btn-page-header-dropdown {
  width: 35px;
  height: 35px;
  font-size: 14px;
  padding: 0px;
  color: #6b6b6b;
  box-shadow: 0 2px 14px 0 rgba(144, 116, 212, 0.1) !important;
  border: 0; 
}
.main-panel .page-header .btn-page-header-dropdown:after {
  display: none; 
}
.main-panel .page-header .dropdown-menu {
  margin-top: 15px;
  top: 0px !important; 
}
.main-panel .page-header .dropdown-menu:after {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ffffff;
  position: absolute;
  top: -8px;
  right: 32px;
  content: ''; 
}
.main-panel .page-divider {
  height: 0;
  margin: .3rem 0 1rem;
  overflow: hidden;
  border-top: 1px solid #ebecec; 
}

/*      Page Wrapper      */
.page-wrapper {
  min-height: calc(100vh - 57px);
  position: relative; 
}
.page-wrapper.has-sidebar .page-inner {
  margin-right: 22.5rem; 
}
.page-navs {
  position: relative;
  display: block;
  padding-right: 1rem;
  padding-left: 1rem;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
  z-index: 1; 
}
.page-navs .nav .nav-link {
  padding: 1rem !important;    
}
.page-navs .nav-line {
  border: 0px !important; 
}
.page-navs .nav-line .nav-link {
  border-bottom-width: 3px !important; 
}
.nav-scroller .nav {
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap; 
}

/* Statistics data Start */
.statistics-grid.card {
    -webkit-border-radius:  25px;
       -moz-border-radius:  25px;
         -o-border-radius:  25px;
            border-radius:  25px;
}
.statistics-detail { color: #fff; }
h3.number {
  font-weight: 500;
  color: #ffffff;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  transition: .3s ease;
  margin-bottom: 0;
}
p.stat-text {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 20px;
}
.statistics-grid .statistics-icon {
    background: #fff;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50%;
}
.statistics-grid .statistics-icon i {
    font-size: 25px;
    line-height: 50px;
    transition: all 0.3s ease-in-out;
}
.statistics-grid .tile-footer a { color: #fff; }
.statistics-grid .tile-footer a:hover { color: #1b2839; }
/* Statistics data End */

@media (min-width: 992px) {
  .page-navs {
    padding-right: 2rem;
    padding-left: 2rem; 
  } 
}

@media (min-width: 576px) {
  .page-inner {
    padding-right: 1rem;
    padding-left: 1rem; 
  } 
}
@media (min-width: 992px) {
  .page-inner {
    padding-right: 2rem;
    padding-left: 2rem; 
  } 
}
.page-inner-fill {
  padding: 0;
  height: calc(100% - 57px);
  display: flex;
  flex-direction: column; 
}

.page-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 22.5rem;
  box-shadow: none;
  transform: translate3d(100%, 0, 0);
  overflow: auto;
  z-index: 999;
  transition: transform .2s ease-in-out;
  border-left: 1px solid rgba(61, 70, 79, 0.125) !important; 
}
.page-sidebar .back {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
  font-size: 15px; 
}

.page-sidebar-section {
  flex: 1;
  overflow-y: auto; 
}

@media (min-width: 1200px) {
  .page-sidebar {
    transform: translateZ(0); 
  } 
}
@media (max-width: 1200px) {
  .page-wrapper.has-sidebar .page-inner {
    margin-right: 0px; 
  }
  .pagesidebar_open .page-sidebar {
    transform: translate3d(0, 0, 0) !important;
    max-width: unset; 
  } 
  
}
 @media (max-width: 1199px) {
  table .form-control { width: auto; }
  div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate { margin-bottom: 20px; }
}

.page-with-aside { display: flex; }
.page-with-aside .page-aside {
  width: 280px;
  min-height: 100vh;
  border-right: 1px solid #f1f1f1;
  padding: 15px 0; 
}
.page-with-aside .page-aside .aside-header { padding: 15px 22px; }
.page-with-aside .page-aside .aside-header .title { font-size: 24px; }
.page-with-aside .page-aside .aside-header .description { font-size: 12px; }
.page-with-aside .page-aside .aside-nav .nav { flex-direction: column; }
.page-with-aside .page-aside .aside-nav .nav > li {
  padding: 8px 22px;
  margin-bottom: 5px; 
}
.page-with-aside .page-aside .aside-nav .nav > li:hover, .page-with-aside .page-aside .aside-nav .nav > li:focus, .page-with-aside .page-aside .aside-nav .nav > li.active {
  background: rgba(51, 51, 51, 0.08); 
}
.page-with-aside .page-aside .aside-nav .nav > li.active {
  padding: 12px 22px;
  font-weight: 600; 
}
.page-with-aside .page-aside .aside-nav .nav > li.active > a {
  color: #666666 !important; 
}
.page-with-aside .page-aside .aside-nav .nav > li > a {
  color: #83848a;
  display: flex;
  align-items: center;
  font-size: 12px; 
}
.page-with-aside .page-aside .aside-nav .nav > li > a:hover, .page-with-aside .page-aside .aside-nav .nav > li > a:focus { text-decoration: none;}
.page-with-aside .page-aside .aside-nav .nav > li > a i {
  font-size: 20px;
  margin-right: 15px;
  color: #a1a2a6; 
}
.page-with-aside .page-aside .aside-nav .label {
  padding: 5px 22px;
  margin-top: 22px;
  margin-bottom: 5px;
  display: block; 
}
.page-with-aside .page-aside .aside-compose { padding: 25px 22px; }
.page-with-aside .page-content { width: calc(100% - 280px); }

.footer {
  border-top: 1px solid #eee;
  padding: 10px;
  background-color: #ffffff;
  position: fixed;
  bottom:0;
  left:10 !important;
  /* width: 100%; */
  width: 100vw;
  height: 7%;
  
}

.footer .container, .footer .container-fluid {
  display: flex;
  align-items: center; 
  
  
}
.space{
  width: 50px;
}


/*    sidebar minimized   */
@media screen and (min-width: 991px) {
  .sidebar_minimize .main-panel {
    width: calc(100% - 75px);
    transition: all .3s; 
  }
  .sidebar_minimize .logo-header {
    width: 75px;
    transition: all .3s;
    padding: 0px;
    text-align: center; 
  }
  .sidebar_minimize .logo-header .big-logo {
    margin-right: 0px; 
  }
  .sidebar_minimize .logo-header .logo {
    position: absolute;
    transform: translate3d(25px, 0, 0);
    opacity: 0; 
  }
  .sidebar_minimize .logo-header .logo img {
    display: none; 
  }
  .sidebar_minimize .logo-header .nav-toggle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    right: 0 !important; 
  }
  .sidebar_minimize .sidebar {
    width: 75px;
    transition: all .3s; 
  }
  .sidebar_minimize .sidebar .sidebar-wrapper {
    width: 75px;
    transition: all .3s; 
  }
  .sidebar_minimize .sidebar .sidebar-wrapper .user {
    padding-left: 0px;
    padding-right: 0px; 
  }
  .sidebar_minimize .sidebar .sidebar-wrapper .user [class^="profile-"] {
    float: none !important;
    margin: auto; 
  }
  .sidebar_minimize .sidebar .sidebar-wrapper .user .info { display: none; }
  .sidebar_minimize .sidebar .sidebar-wrapper .user .info span { display: none; }
  .sidebar_minimize .sidebar .sidebar-wrapper .nav-item { position: relative; }
  .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a .letter-icon { display: block !important; }
  .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a i { margin-right: unset; }
  .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a .badge, .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a span, .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a .caret, .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a p {
    display: none;
    transition: all .3s; 
  }
  .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a .sidebar-mini-icon {
    display: block !important;
    margin-right: 0px; 
  }
  .sidebar_minimize .sidebar .sidebar-wrapper .nav-item.submenu .nav-collapse, .sidebar_minimize .sidebar .sidebar-wrapper .nav-item.active .nav-collapse {
    display: none; 
  }
  .sidebar_minimize .sidebar .sidebar-wrapper .nav-section .text-section { display: none; }
  .sidebar_minimize .sidebar .sidebar-wrapper .nav-section .sidebar-mini-icon { display: block; }
  .sidebar_minimize .sidebar:hover { width: 250px; }
  .sidebar_minimize .sidebar:hover .sidebar-wrapper { width: 250px; }
  .sidebar_minimize .sidebar:hover .sidebar-wrapper .user {
    padding-left: 15px;
    padding-right: 15px; 
  }
  .sidebar_minimize .sidebar:hover .sidebar-wrapper .user [class^="profile-"] {
    float: left !important;
    margin-right: 11px !important; 
  }
  .sidebar_minimize .sidebar:hover .sidebar-wrapper .user .info { display: block; }
  .sidebar_minimize .sidebar:hover .sidebar-wrapper .user .info span { display: block; }
  .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item a i { margin-right: 15px; }
  .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item a .badge, .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item a span, .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item a .caret, .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item a p {
    display: block; 
  }
  .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item a .sidebar-mini-icon {
    display: block !important;
    margin-right: 15px; 
  }
  .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item.submenu .nav-collapse, .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item.active .nav-collapse {
    display: block; 
  }
  .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-section .sidebar-mini-icon { display: none; }
  .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-section .text-section { display: block; }
  .sidebar_minimize.sidebar_minimize_hover .logo-header {
    width: 250px;
    padding-left: 25px;
    padding-right: 25px;
    text-align: left; 
  }
  .sidebar_minimize.sidebar_minimize_hover .logo-header .logo {
    opacity: 1 !important;
    transform: translate3d(0, 0, 0) !important;
    position: relative !important; 
  }
  .sidebar_minimize.sidebar_minimize_hover .logo-header .logo img { display: inline-block !important; }
  .sidebar_minimize.sidebar_minimize_hover .main-panel { width: calc(100% - 250px); }

  .sidebar_minimize_hover .logo-header .nav-toggle {
    right: 18px !important;
    transform: translateX(0%) !important;
    left: unset; 
  } 
 
}
/*    overlay sidebar    */
.overlay-sidebar:not(.is-show) .sidebar { left: -250px; }
.overlay-sidebar .main-panel { width: 100% !important; }

/*    compact wrapper    */
@media screen and (min-width: 991px) {
  .compact-wrapper .main-header .logo-header { width: 175px; }
  .compact-wrapper .sidebar { width: 175px; }
  .compact-wrapper .sidebar .badge {
    position: absolute;
    top: 8px;
    right: 8px; 
  }
  .compact-wrapper .sidebar .text-section { text-align: center; }
  .compact-wrapper .sidebar .nav > .nav-item a { flex-direction: column; }
  .compact-wrapper .sidebar .nav > .nav-item a i { margin-right: 0px; }
  .compact-wrapper .sidebar .nav > .nav-item a p { margin-right: 0px; }
  .compact-wrapper .sidebar .nav > .nav-item a .caret { display: none; }
  .compact-wrapper .sidebar .nav-collapse li a .sub-item {
    margin-left: 0px;
    text-align: center; 
  }
  .compact-wrapper .sidebar .nav-collapse li a .sub-item:before { display: none; }
  .compact-wrapper .main-panel { width: calc(100% - 175px); } 
}
/*    classic     */
@media screen and (min-width: 991px) {
  .classic-wrapper .classic-grid { margin: 93px 40px 30px; }
  .classic-wrapper .main-header { top: 0; }
  .classic-wrapper .main-header .logo-header {
    padding: 0 40px;
    width: 290px; 
  }
  .classic-wrapper .sidebar {
    position: relative;
    float: left;
    margin-top: 0px; 
  }
  .classic-wrapper .sidebar .sidebar-wrapper {
    max-height: unset;
    min-height: 100%; 
  }
  .classic-wrapper .navbar-header { padding-right: 30px; }
  .classic-wrapper .main-panel { height: unset; }
  .classic-wrapper .main-panel .content, .classic-wrapper .main-panel .content-full { margin-top: 0px; }
  .classic-wrapper .page-inner {
    padding-right: 0px;
    padding-top: 5px; 
  }
  .classic-wrapper .board { height: 100%; }

  .sidebar_minimize .classic-wrapper .logo-header .logo {
    position: relative;
    transform: unset;
    opacity: 1; 
  }
  .sidebar_minimize .classic-wrapper .logo-header .logo img { display: inline-block; }
  .sidebar_minimize .classic-wrapper .logo-header .nav-toggle {
    left: unset;
    transform: unset;
    right: 18px !important; 
  } 
}
.classic-wrapper { height: unset; }
.classic-wrapper .main-panel { height: unset; }
.classic-wrapper .footer { position: unset; }

.classic-grid {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
}

/*    Static Sidebar    */
@media screen and (min-width: 991px) {
  .static-sidebar { height: unset; }
  .static-sidebar .sidebar {
    position: static;
    float: left; 
  }
  .static-sidebar .sidebar .sidebar-wrapper {
    max-height: unset;
    min-height: 100%; 
  }
  .static-sidebar .main-panel { height: unset; }
  .static-sidebar .main-panel .content { margin-bottom: 60px; }
  .static-sidebar .footer {
    position: absolute;
    bottom: 0; 
  } 
}
/*      Mail      */
.mail-wrapper .toggle-email-nav {
  margin-top: 10px;
  display: none; 
}
.mail-wrapper .mail-content .inbox-head, .mail-wrapper .mail-content .email-head { padding: 35px 25px 20px; }
.mail-wrapper .mail-content .inbox-head h3, .mail-wrapper .mail-content .email-head h3 {
  font-size: 22px;
  font-weight: 300;
  margin: 0px; 
}
.mail-wrapper .mail-content .email-head {
  padding: 35px 25px;
  border-bottom: 1px solid #f1f1f1; 
}
.mail-wrapper .mail-content .email-head .favorite {
  color: #eee;
  margin-right: 5px; 
}
.mail-wrapper .mail-content .email-head .favorite.active { color: #FFC600; }
.mail-wrapper .mail-content .email-head .controls { margin-left: auto; }
.mail-wrapper .mail-content .email-head .controls > a {
  color: #9c9c9c;
  font-size: 18px;
  padding: 0 5px; 
}
.mail-wrapper .mail-content .email-head .controls > a:hover {
  text-decoration: none;
  opacity: 0.8; 
}
.mail-wrapper .mail-content .email-head .controls > a:last-child { padding-right: 0px; }
.mail-wrapper .mail-content .email-sender {
  padding: 14px 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f1; 
}
.mail-wrapper .mail-content .email-sender .profile { padding-right: 12px; }
.mail-wrapper .mail-content .email-sender .profile img {
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%; 
}
.mail-wrapper .mail-content .email-sender .date { margin-left: auto; }
.mail-wrapper .mail-content .email-sender .sender .action { display: inline-block; }
.mail-wrapper .mail-content .email-sender .sender .action > a { cursor: pointer; }
.mail-wrapper .mail-content .email-body { padding: 30px 28px; }
.mail-wrapper .mail-content .email-attachments {
  padding: 25px 28px;
  border-top: 1px solid #f1f1f1; 
}
.mail-wrapper .mail-content .email-attachments .title {
  font-weight: 400;
  margin-bottom: 10px; 
}
.mail-wrapper .mail-content .email-attachments .title span { font-weight: 400; }
.mail-wrapper .mail-content .email-attachments ul {
  padding-left: 0px;
  list-style: none; 
}
.mail-wrapper .mail-content .email-attachments ul li { padding: 6px 0; }
.mail-wrapper .mail-content .email-attachments ul li a { font-weight: 400; }
.mail-wrapper .mail-content .email-attachments ul li a:hover { text-decoration: none; }
.mail-wrapper .mail-content .email-attachments ul li a i {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle; 
}
.mail-wrapper .mail-content .email-attachments ul li a span { font-weight: 400; }
.mail-wrapper .mail-content .inbox-body { padding: 20px 0px; }
.mail-wrapper .mail-content .inbox-body .mail-option {
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex; 
}
.mail-wrapper .mail-content .inbox-body .mail-option .chk-all { display: inline-block; }
.mail-wrapper .mail-content .inbox-body .mail-option .btn-option {
  color: #555 !important;
  border: 1px solid #ebedf2 !important;
  font-weight: 600;
  background: #ffffff !important;
  box-shadow: 2px 2px 3px 0px #f2f1f1 !important; 
}
.mail-wrapper .mail-content .inbox-body .mail-option .form-check { padding: 0; }
.mail-wrapper .mail-content .inbox-body .mail-option .form-check .form-check-sign:before {
  border: 1px solid #eee;
  background: #eeeeee; 
}
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item {
  padding: 14px 20px;
  display: table;
  cursor: pointer;
  position: relative;
  font-size: 12px;
  width: 100%;
  border-top: 1px solid #f1f1f1; 
}
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item:hover { background: #f6f5f5; }
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions, .mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail {
  vertical-align: top;
  display: table-cell; 
}
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions { width: 50px; }
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions .custom-checkbox { margin-right: 0px; }
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions .favorite {
  color: #eee;
  font-size: 18px; 
}
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions .favorite:hover {
  text-decoration: none;
  color: #969696; 
}
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions .favorite.active, .mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions .favorite.active:hover {
  color: #FFC600; 
}
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail p, 
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail .msg { font-size: 12px; }
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail .msg {
  margin-bottom: 0px;
  margin-top: 8px; 
}
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail .from { font-size: 13px; }
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail .date {
  font-size: 12px;
  display: flex;
  align-items: center; 
}
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail .date .paperclip {
  font-size: 16px;
  padding-right: 4px; 
}
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item.unread {
  font-weight: 400;
  background: #fbfbfb; 
}
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item.unread:after {
  content: '';
  display: block;
  position: absolute;
  width: 3px;
  background: #ff3030;
  top: -1px;
  left: 0px;
  bottom: -1px;
  height: calc(100% + 2px); 
}
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item.unread .email-list-detail .from { font-weight: 600; }
.mail-wrapper .mail-content .email-compose-fields, .mail-wrapper .mail-content .email-editor { padding: 20px 25px; }
.mail-wrapper .mail-content .email-compose-fields {
  padding: 20px 25px;
  border-bottom: 1px solid #f1f1f1; 
}
.mail-wrapper .mail-content .email-action {
  text-align: right;
  margin-bottom: 15px; 
}
.mail-wrapper .mail-content .email-action > .btn { margin-right: 7px; }
.mail-wrapper .mail-content .email-action > .btn:last-child { margin-right: 0px; }

/*    Flex-1    */
.flex-1 {
  -ms-flex: 1;
  flex: 1; 
}

/*    Metric    */
.metric {
  display: flex;
  padding: 1rem;
  flex-direction: column; 
}

/*    profile    */
.profile {
  position: relative;
  display: inline-block; 
}

.profile-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover; 
}

.profile-title {
  width: 100%;
  height: 100%;
  background-color: #0071e8;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center; 
}

.profile-online::before, .profile-offline::before, .profile-away::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: '';
  border: 2px solid #fff; 
}
.profile-online::before { background-color: #1bb73f; }
.profile-offline::before { background-color: #97a2b1; }
.profile-away::before { background-color: #ff9d21; }
.profile {
  width: 3rem;
  height: 3rem; 
}
.profile .border { border-width: 3px !important; }
.profile .rounded { border-radius: 6px !important; }
.profile .profile-title { font-size: 18px; }

.profile-xs {
  width: 1.65rem;
  height: 1.65rem; 
}
.profile-xs .border { border-width: 2px !important; }
.profile-xs .rounded { border-radius: 4px !important; }
.profile-xs .profile-title { font-size: 12px; }
.profile-xs.profile-online::before, .profile-xs.profile-offline::before, .profile-xs.profile-away::before { border-width: 1px; }

.profile-sm {
  width: 2.5rem;
  height: 2.5rem; 
}
.profile-sm .border { border-width: 3px !important; }
.profile-sm .rounded { border-radius: 4px !important; }
.profile-sm .profile-title { font-size: 15px; }
.profile-sm.profile-online::before, .profile-sm.profile-offline::before, .profile-sm.profile-away::before { border-width: 2px; }

.profile-lg {
  width: 3.75rem;
  height: 3.75rem; 
}
.profile-lg .border { border-width: 3px !important; }
.profile-lg .rounded { border-radius: 8px !important; }
.profile-lg .profile-title { font-size: 24px; }
.profile-lg.profile-online::before, .profile-lg.profile-offline::before, .profile-lg.profile-away::before { border-width: 3px; }

.profile-xl {
  width: 5rem;
  height: 5rem; 
}
.profile-xl .border { border-width: 4px !important; }
.profile-xl .rounded { border-radius: 8px !important; }
.profile-xl .profile-title { font-size: 28px; }
.profile-xl.profile-online::before, .profile-xl.profile-offline::before, .profile-xl.profile-away::before { border-width: 4px; }

.profile-xxl {
  width: 5.125rem;
  height: 5.125rem; 
}
.profile-xxl .border { border-width: 6px !important; }
.profile-xxl .rounded { border-radius: 8px !important; }
.profile-xxl .profile-title { font-size: 30px; }
.profile-xxl.profile-online::before, .profile-xxl.profile-offline::before, .profile-xxl.profile-away::before { border-width: 4px; }

@media (min-width: 768px) {
  .profile-xxl {
    width: 8rem;
    height: 8rem; 
  }
  .profile-xxl .border { border-width: 4px !important; }
  .profile-xxl .rounded { border-radius: 12px !important; }
  .profile-xxl .profile-title { font-size: 42px; }
  .profile-xxl.profile-online::before, .profile-xxl.profile-offline::before, .profile-xxl.profile-away::before { border-width: 4px; } 
}

.profile-group { display: inline-flex; }
.profile-group .profile + .profile { margin-left: -.75rem; }
.profile-group .profile-xs + .profile-xs { margin-left: -.40625rem; }
.profile-group .profile-sm + .profile-sm { margin-left: -.625rem; }
.profile-group .profile-lg + .profile-lg { margin-left: -1rem; }
.profile-group .profile-xl + .profile-xl { margin-left: -1.28125rem; }
.profile-group .profile:hover { z-index: 1; }

.border-dark { border-color: #202940 !important; }

/* 		Breadcrumb		*/
.breadcrumb {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    border-radius: 0;
    background: transparent;
    margin-left: 20px;
}
.breadcrumb:last-child { color: #acacac; }
.breadcrumb > li { display: inline-block; }
.breadcrumb > li + li::before {
    padding: 0 5px;
    color: #ccc;
    content: "/\00a0";
}
.header .breadcrumb li a {
    text-decoration: none;
    color: #6d6d6d;
}
.header .breadcrumb li a:hover, .breadcrumb > li:last-child a { color: #ff3030; }

/*     Card     */
.card, .card-light {
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 30px;
  -webkit-box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1);
  -moz-box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1);
  box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1);
  border: 0px; 
}
.card .card-header, .card-light .card-header {
  padding: 1rem 1.25rem;
  background-color: transparent;
  border-bottom: 1px solid #ebecec !important; 
}
.card .card-header:first-child, .card-light .card-header:first-child { border-radius: 0px; }
.card .card-header .card-head-row, .card-light .card-header .card-head-row {
  display: flex;
  align-items: center;
   
}
.card .card-header .card-head-row .card-tools, .card-light .card-header .card-head-row .card-tools {
  margin-left: auto;
  float: right;
  padding-left: 15px; 
  
}
.card .separator-solid, .card-light .separator-solid {
  border-top: 1px solid #ebecec;
  margin: 15px 0; 
}
.card .separator-dashed, .card-light .separator-dashed {
  border-top: 1px dashed #ebecec;
  margin: 15px 0; 
}
.card .separator-dot, .card-light .separator-dot {
  border-top: 1px dotted #ebecec;
  margin: 15px 0; 
}
.card .full-width-separator, .card-light .full-width-separator { margin: 15px -20px 15px; }
.card .card-body, .card-light .card-body { padding: 1.25rem; }
.card .card-footer, .card-light .card-footer {
  background-color: transparent;
  line-height: 30px;
  border-top: 1px solid #ebecec !important;
  font-size: 13px; 
}
.card .pull-in, .card-light .pull-in {
  margin-left: -1.25rem;
  margin-right: -1.25rem; 
}
.card .pull-in.sparkline-fix, .card-light .pull-in.sparkline-fix {
  margin-left: -1.35rem;
  margin-right: -1.35rem;
  margin-bottom: -3px; 
}
.card .chart-as-background, .card-light .chart-as-background {
  position: absolute;
  bottom: 0;
  width: calc(100% + 2px); 
}
.card .card-action, .card-light .card-action {
  padding: 30px;
  background-color: transparent;
  line-height: 30px;
  border-top: 1px solid #ebecec !important;
  font-size: 14px; 
}
.card .card-footer hr, .card-light .card-footer hr {
  margin-top: 5px;
  margin-bottom: 5px; 
}
.card .card-footer .legend, .card-light .card-footer .legend { display: inline-block; }

@media screen and (max-width: 476px) {
  .card .card-header .card-head-row:not(.card-tools-still-right) .card-tools {
    margin-left: 0px;
    float: left;
    padding-left: 0px;
    padding-top: 10px; 
  } 
}

.card.full-height { height: calc(100% - 30px); }
.card-space { padding: 0 30px; }
.card-space > .card-header, .card-space > .card-body, .card-space > .card-footer, .card-space > .card-action {
  padding-left: 0px !important;
  padding-right: 0px !important; 
}

.card-with-nav .card-header {
  border-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important; 
}
.card-with-nav .card-body { padding: 15px 25px !important; }

.card-list { padding: 10px 0; }
.card-list .item-list {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  align-items: center; 
}
.card-list .item-list .info-user { flex: 1; }
.card-list .item-list .info-user .username, .card-list .item-list .info-user a.username {
  color: #ff3030;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 400; 
}
.card-list .item-list .info-user .status {
  font-size: 12px;
  color: #7d7b7b; 
}
.card-title {
  margin: 0;
  color: #1b2839;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6; 
  
  
}
.card-title a, .card-title a:hover, .card-title a:focus {
  color: #666666;
  text-decoration: none; 
}
.card-sub {
  display: block;
  margin: 5px 0 10px 0;
  font-size: .9rem;
  background: #f7f8fa;
  color: #666666;
  padding: 0.85rem 1.5rem;
  border-radius: 4px;
  line-height: 1.82; 
}
.card-category {
  margin-top: 8px;
  font-size: 14px;
  color: #8d9498;
  margin-bottom: 0px;
  word-break: normal; 
}
label {
  font-size: 14px;
  font-weight: 400;
  color: #8d9498;
  margin-bottom: 0px; 
}
.card-transparent {
  background: transparent !important;
  box-shadow: none;
  border-color: transparent !important; 
}

/*     Card Stats    */
.card-stats .card-body { padding: 15px !important; }
.card-stats .card-title { margin-bottom: 0px !important; }
.card-stats .card-category { margin-top: 0px; }
.card-stats .col-icon {
  width: 65px;
  height: 65px;
  margin-left: 15px; 
}
.card-stats .icon-big {
  width: 100%;
  height: 100%;
  font-size: 2.2em;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center; 
}
.card-stats .icon-big.icon-default, .card-stats .icon-big.icon-primary, .card-stats .icon-big.icon-secondary, .card-stats .icon-big.icon-success, .card-stats .icon-big.icon-info, .card-stats .icon-big.icon-warning, .card-stats .icon-big.icon-danger {
  border-radius: 5px; 
}
.card-stats .icon-big.icon-default i, .card-stats .icon-big.icon-primary i, .card-stats .icon-big.icon-secondary i, .card-stats .icon-big.icon-success i, .card-stats .icon-big.icon-info i, .card-stats .icon-big.icon-warning i, .card-stats .icon-big.icon-danger i {
  color: #ffffff !important; 
}
.card-stats .icon-big.icon-default { background: #1a2035; }
.card-stats .icon-big.icon-primary { background: #ff3030; }
.card-stats .icon-big.icon-secondary { background: #0071e8; }
.card-stats .icon-big.icon-success { background: #1bb73f; }
.card-stats .icon-big.icon-warning { background: #ff9d21; }
.card-stats .icon-big.icon-info { background: #48abf7; }
.card-stats .icon-big.icon-danger { background: #dc3545; }
.card-stats .icon-big.round { border-radius: 50% !important; }
.card-stats .col-stats {
  align-items: center;
  display: flex;
  padding-left: 15px; 
}

/*     Card Task     */
.card-tasks .table { margin-bottom: 0px; }
.card-tasks .table .form-check { padding: 0 0 0 0.75rem !important; }
.card-tasks .table .form-check label { margin-bottom: 0px !important; }
.card-tasks .table tbody td:first-child, .card-tasks .table thead th:first-child {
  padding-left: 15px;
  padding-right: 15px; 
}
.card-tasks .table tbody td:last-child, .card-tasks .table thead th:last-child { padding-right: 15px; }
.card-tasks .table tbody tr:last-child td { border-bottom-width: 0px !important; }
.card-tasks .card-body {
  padding-top: 0px;
  padding-bottom: 0px; 
}
.card-tasks .card-body .table td { font-size: 13px; }
.card-tasks .card-body .table td .btn {
  font-size: 15px;
  opacity: 0.7;
  transition: all .3s; 
}
.card-tasks .card-body .table td:hover .btn { opacity: 1; }
.card-tasks .form-button-action { display: block !important; }

/*     Card States    */
.card-dark, .card-default, .card-primary, .card-secondary, .card-info, .card-success, .card-warning, .card-danger {
  color: #ffffff;
  border: 0px; 
}
.card-dark .card-header, .card-default .card-header, .card-primary .card-header, .card-secondary .card-header, .card-info .card-header, .card-success .card-header, .card-warning .card-header, .card-danger .card-header {
  border-bottom: transparent !important; 
}
.card-dark .card-category, .card-default .card-category, .card-primary .card-category, .card-secondary .card-category, .card-info .card-category, .card-success .card-category, .card-warning .card-category, .card-danger .card-category, .card-dark .card-title, .card-default .card-title, .card-primary .card-title, .card-secondary .card-title, .card-info .card-title, .card-success .card-title, .card-warning .card-title, .card-danger .card-title, .card-dark label, .card-default label, .card-primary label, .card-info label, .card-success label, .card-warning label, .card-danger label {
  color: #ffffff; 
}
.card-dark .icon-big > i, .card-default .icon-big > i, .card-primary .icon-big > i, .card-secondary .icon-big > i, .card-info .icon-big > i, .card-success .icon-big > i, .card-warning .icon-big > i, .card-danger .icon-big > i {
  color: #ffffff !important; 
}
.card-dark .card-footer, .card-default .card-footer, .card-primary .card-footer, .card-secondary .card-footer, .card-info .card-footer, .card-success .card-footer, .card-warning .card-footer, .card-danger .card-footer {
  border-top: transparent !important; 
}
.card-default { background: #1a2035 !important; }
.card-primary { background: #ff3030 !important; }
.card-secondary { background: #0071e8 !important; }
.card-info { background: #48abf7 !important; }
.card-success { background: #1bb73f !important; }
.card-warning { background: #ff9d21 !important; }
.card-danger { background: #dc3545 !important; }
.card-round { border-radius: 5px; }

/*     Progress Card    */
.progress-card { margin-bottom: 25px; }
.progress-card .progress-status {
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; 
}

/*      Card Posts    */
.card-post .info-post .username {
  margin-bottom: 0px;
  font-weight: 600; 
}
.card-post .info-post .date { margin-bottom: 0px; }

/*    Card Product    */
.row-cardProduct {
  padding: 0 5px;
  white-space: nowrap;
  overflow-x: auto;
  display: block !important;
  margin-right: -2rem;
  width: unset !important; 
}
.col-cardProduct {
  width: 225px;
  padding: 0 10px;
  display: inline-block; 
}
.card-product {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.12);
  margin-bottom: 15px; 
}
.card-product .product-summary { padding: 15px; }

@media screen and (max-width: 768px) {

  .col-cardProduct { width: 175px; }
  .card-product .title-product { font-size: 14px; }
  .card-product .price-product { font-size: 18px; } 
}
/*     Card Shadow    */
.skew-shadow {
  position: relative;
  overflow: hidden; 
}
.skew-shadow:before {
  content: '';
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  width: 50%;
  min-width: 150px;
  height: 100%;
  top: 0;
  right: -25%;
  transform: skewX(-32.5deg); 
}

.bubble-shadow {
  position: relative;
  overflow: hidden; 
}
.bubble-shadow:before {
  position: absolute;
  top: -10%;
  right: -140px;
  width: 300px;
  height: 300px;
  content: "";
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05); 
}
.bubble-shadow:after {
  position: absolute;
  top: -65px;
  right: 80px;
  width: 150px;
  height: 150px;
  content: "";
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05); 
}
.curves-shadow {
  position: relative;
  overflow: hidden; 
}
.curves-shadow:before {
  content: '';
  position: absolute;
 /*  background: url(../img/img-shadow.png); */
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; 
}

/*     Card Annoucement    */
.card-annoucement .card-body { padding: 50px 25px; }
.card-annoucement .card-opening {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.01em; 
}
.card-annoucement .card-desc {
  padding: 15px 0;
  font-size: 16px;
  line-height: 1.65;
  font-weight: 300; 
}
.card-annoucement.card-primary .btn-light { color: #ff3030 !important; }
.card-annoucement.card-success .btn-light { color: #1bb73f !important; }
.card-annoucement.card-secondary .btn-light { color: #0071e8 !important; }
.card-annoucement.card-default .btn-light { color: #1a2035 !important; }
.card-annoucement.card-info .btn-light { color: #48abf7 !important; }
.card-annoucement.card-danger .btn-light { color: #dc3545 !important; }
.card-annoucement.card-warning .btn-light { color: #ff9d21 !important; }

/*     Card Profile     */
.card-profile { color: #666666; }
.card-profile .profile-picture {
  text-align: center;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -41px;
  width: 100%;
  box-sizing: border-box; 
}
.card-profile .user-profile .name {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px; 
}
.card-profile .user-profile .job {
  color: #83848a;
  margin-bottom: 5px; 
}
.card-profile .user-profile .desc {
  color: #bbb;
  margin-bottom: 15px;
}
.card-profile .user-profile .social-media { margin-bottom: 20px; }
.card-profile .user-profile .social-media .btn { padding: 5px !important; }
.card-profile .user-profile .social-media .btn i { font-size: 22px !important; }
.card-profile .user-stats { margin-bottom: 10px; }
.card-profile .user-stats [class^="col"] { border-right: 1px solid #ebebeb; }
.card-profile .user-stats [class^="col"]:last-child { border-right: 0px; }
.card-profile .user-stats .number {
  font-weight: 400;
  font-size: 15px; 
}
.card-profile .user-stats .title { color: #7d7b7b; }
.card-profile .card-header {
  border-bottom: 0px;
  height: 100px;
  position: relative; 
}
.card-profile .card-body { padding-top: 60px; }
.card-profile .card-footer { border-top: 0px; }
.card-profile.card-secondary .card-header { background: #0071e8; }

/*     Accordion     */
.accordion .card {
  border-radius: 5px !important;
  background: #f7f7f7 !important;
  color: #666666 !important;
  border: 0;
  box-shadow: none; 
}
.accordion .card .span-icon {
  font-size: 22px;
  padding-left: 15px;
  padding-right: 15px; 
}
.accordion .card > .card-header {
  border: 0px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 0 !important; 
}
.accordion .card > .card-header > .span-mode { margin-left: auto; }
.accordion .card > .card-header > .span-mode:before {
  content: "\f068" !important;
  font-family: 'Font Awesome 5 Solid';
  font-weight: 900;
  font-size: 16px; 
}
.accordion .card > .card-header.collapsed > .span-mode:before {  content: "\f067" !important; }
.accordion .card .card-body {
  border-top: 1px solid #ebebeb;
  padding: 30px; 
}
.accordion.accordion-default .card .card-header, .accordion.accordion-default .card .card-header .btn-link, .accordion.accordion-primary .card .card-header, .accordion.accordion-primary .card .card-header .btn-link, .accordion.accordion-secondary .card .card-header, .accordion.accordion-secondary .card .card-header .btn-link, .accordion.accordion-info .card .card-header, .accordion.accordion-info .card .card-header .btn-link, .accordion.accordion-success .card .card-header, .accordion.accordion-success .card .card-header .btn-link, .accordion.accordion-warning .card .card-header, .accordion.accordion-warning .card .card-header .btn-link, .accordion.accordion-danger .card .card-header, .accordion.accordion-danger .card .card-header .btn-link {
  font-size: 14px; 
}
.accordion.accordion-default .card .card-header { color: #1a2035; }
  .accordion.accordion-default .card .card-header .btn-link { color: #1a2035 !important; }

.accordion.accordion-primary .card .card-header { color: #ff3030; }
  .accordion.accordion-primary .card .card-header .btn-link { color: #ff3030 !important; }

.accordion.accordion-secondary .card .card-header { color: #0071e8; }
  .accordion.accordion-secondary .card .card-header .btn-link { color: #0071e8 !important; }

.accordion.accordion-info .card .card-header { color: #48abf7; }
  .accordion.accordion-info .card .card-header .btn-link { color: #48abf7 !important; }

.accordion.accordion-success .card .card-header { color: #1bb73f; }
  .accordion.accordion-success .card .card-header .btn-link { color: #1bb73f !important; }

.accordion.accordion-warning .card .card-header { color: #ff9d21; }
  .accordion.accordion-warning .card .card-header .btn-link { color: #ff9d21 !important; }

.accordion.accordion-danger .card .card-header { color: #dc3545; }
  .accordion.accordion-danger .card .card-header .btn-link { color: #dc3545 !important; }

.border-transparent { border-color: transparent !important; }

.gutters-0 {
  margin-right: 0;
  margin-left: 0; 
}
.gutters-0 > .col, .gutters-0 > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; 
}
.gutters-0 .card { margin-bottom: 0; }

.gutters-xs {
  margin-right: -0.25rem;
  margin-left: -0.25rem; 
}
.gutters-xs > .col, .gutters-xs > [class*="col-"] {
  padding-right: 0.25rem;
  padding-left: 0.25rem; 
}
.gutters-xs .card { margin-bottom: 0.5rem; }

.gutters-sm {
  margin-right: -0.5rem;
  margin-left: -0.5rem; 
}
.gutters-sm > .col, .gutters-sm > [class*="col-"] {
  padding-right: 0.5rem;
  padding-left: 0.5rem; 
}
.gutters-sm .card { margin-bottom: 1rem; }

.gutters-lg {
  margin-right: -1rem;
  margin-left: -1rem; 
}
.gutters-lg > .col, .gutters-lg > [class*="col-"] {
  padding-right: 1rem;
  padding-left: 1rem; 
}
.gutters-lg .card { margin-bottom: 2rem; }

.gutters-xl {
  margin-right: -1.5rem;
  margin-left: -1.5rem; 
}
.gutters-xl > .col, .gutters-xl > [class*="col-"] {
  padding-right: 1.5rem;
  padding-left: 1.5rem; 
}
.gutters-xl .card { margin-bottom: 3rem; }

.stamp {
  color: #fff;
  background: #0071e8;
  display: inline-block;
  min-width: 2rem;
  height: 2rem;
  padding: 0 .25rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 3px;
  font-weight: 600; 
}

.stamp-md {
  min-width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem; 
}

/*     Form     */
select {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; 
}
.form-control {
  font-size: 14px;
  border-color: #ebedf2;
  
}
.form-control:focus { border-color: #ebedf2; }
/*     Form Control Sizes    */
.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  padding: .5rem 1rem !important;
  font-size: 1.25rem !important; 
}
.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  padding: .25rem .5rem !important;
  font-size: .875rem !important;
  line-height: 1.5; 
}
.form-control::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.7; 
}
.form-control:-moz-placeholder {
  color: inherit;
  opacity: 0.7; 
}
.form-control::-moz-placeholder {
  color: inherit;
  opacity: 0.7; 
}
.form-control:-ms-input-placeholder {
  color: inherit;
  opacity: 0.7; 
}
.form-control::-ms-input-placeholder {
  color: inherit;
  opacity: 0.7; 
}
.input-group-text {
  border-color: #ebedf2 !important; 
}
.form-button-action {
  display: inline-flex; 
}
.form-check-label, .form-radio-label {
  margin-right: 15px; 
}

/*     CheckBox Input    */
.select-all-checkbox + .form-check-sign:before {
  background: #ccc !important;
  border-color: #ccc !important; 
}
.form-check [type="checkbox"]:not(:checked), .form-check [type="checkbox"]:checked {
  position: absolute;
  left: -9999px; 
}
.form-check [type="checkbox"]:not(:checked) + .form-check-sign, .form-check [type="checkbox"]:checked + .form-check-sign, .form-check [type="checkbox"] + .form-check-sign {
  position: relative;
  padding-left: 25px;
  color: #666666;
  cursor: pointer;
  font-weight: 400; 
}
.form-check [type="checkbox"]:not(:checked) + .form-check-sign:before, .form-check [type="checkbox"]:checked + .form-check-sign:before, .form-check [type="checkbox"] + .form-check-sign:before {
  content: '';
  position: absolute;
  left: 0;
  top: 1px;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  background: transparent;
  border-radius: 4px; 
}
.form-check [type="checkbox"]:not(:checked) + .form-check-sign:after, .form-check [type="checkbox"]:checked + .form-check-sign:after, .form-check [type="checkbox"] + .form-check-sign:after {
  content: "\f00c";
  display: inline-block;
  position: absolute;
  top: -1px;
  left: 2px;
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: 1.3em;
  line-height: 0.8;
  color: #ff3030;
  transition: all .2s;
  font-family: 'Font Awesome 5 Solid'; 
}
.form-check [type="checkbox"]:not(:checked) + .form-check-sign:after {
  opacity: 0;
  transform: scale(0); 
}
.form-check [type="checkbox"]:checked + .form-check-sign { font-weight: 400; }
.form-check [type="checkbox"]:checked + .form-check-sign:after {
  opacity: 1;
  transform: scale(1); 
}
.form-check [type="checkbox"]:disabled:not(:checked) + .form-check-sign:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd; 
}
.form-check [type="checkbox"]:disabled:checked + .form-check-sign:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd; 
}
.form-check [type="checkbox"]:disabled:checked + .form-check-sign:after { color: #999; }
.form-check [type="checkbox"]:disabled + .form-check-sign { color: #aaa; }
.form-check [type="checkbox"]:checked:focus + .form-check-sign:before, .form-check [type="checkbox"]:not(:checked):focus + .form-check-sign:before {
  border: 1px solid #ccc; 
}
.form-check-sign:hover:before { border: 1px solid #ccc !important; }
.form-check { padding-left: 0.75rem; }
.form-check-input {
  position: relative;
  margin-top: .3rem; 
}

/*     Radio Input    */
.form-radio [type="radio"]:not(:checked), .form-radio [type="radio"]:checked {
  position: absolute;
  left: -9999px; 
}
.form-radio [type="radio"]:not(:checked) + .form-radio-sign, .form-radio [type="radio"]:checked + .form-radio-sign {
  color: #666666;
  position: relative;
  padding-left: 2em;
  cursor: pointer;
  line-height: 22px;
  font-weight: 400; 
}
.form-radio [type="radio"]:not(:checked) + .form-radio-sign:before {
  content: "\f18a";
  font-size: 22px;
  font-family: LineAwesome;
  position: absolute;
  left: 0;
  top: auto;
  background: transparent;
  line-height: 1;
  color: #bbb; 
}
.form-radio [type="radio"]:checked + .form-radio-sign:before {
  content: "\f18a";
  font-size: 22px;
  font-family: LineAwesome;
  position: absolute;
  left: 0;
  top: auto;
  background: transparent;
  line-height: 1;
  display: none; 
}
.form-radio [type="radio"]:not(:checked) + .form-radio-sign:after, .form-radio [type="radio"]:checked + .form-radio-sign:after {
  content: "\f1bc";
  position: absolute;
  left: 0px;
  top: auto;
  text-align: center;
  font-size: 22px;
  color: #ff3030;
  transition: all .2s;
  line-height: 1;
  font-family: LineAwesome; 
}
.form-radio [type="radio"]:not(:checked) + .form-radio-sign:after {
  opacity: 0;
  transform: scale(0); 
}
.form-radio [type="radio"]:checked + .form-radio-sign { font-weight: 400; }
.form-radio [type="radio"]:checked + .form-radio-sign:after {
  opacity: 1;
  transform: scale(1); 
}
.form-radio [type="radio"]:disabled:not(:checked) + .form-radio-sign:before {
  box-shadow: none;
  opacity: 0.65; 
}
.form-radio [type="radio"]:disabled:checked + .form-radio-sign:before {
  box-shadow: none;
  opacity: 0.65; 
}
.form-radio [type="radio"]:disabled:checked + .form-radio-sign:after { opacity: 0.65; }
.form-radio [type="radio"]:disabled + .form-radio-sign {
  color: #aaa;
  opacity: 0.65; 
}
.form-radio [type="radio"]:checked:focus + .form-radio-sign:before, .form-radio [type="radio"]:not(:checked):focus + .form-radio-sign:before {
  border: 1px solid #ccc; 
}
.form-radio { padding-left: 0.75rem; }
.form-radio-input {
  position: relative;
  margin-top: .3rem; 
}

/*      Custom Checkbox      */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before { background-color: #ff3030; }
.custom-checkbox.checkbox-default .custom-control-input:checked ~ .custom-control-label::before { background-color: #1a2035; }
.custom-checkbox.checkbox-primary .custom-control-input:checked ~ .custom-control-label::before { background-color: #ff3030; }
.custom-checkbox.checkbox-secondary .custom-control-input:checked ~ .custom-control-label::before { background-color: #0071e8; }
.custom-checkbox.checkbox-info .custom-control-input:checked ~ .custom-control-label::before { background-color: #48abf7; }
.custom-checkbox.checkbox-success .custom-control-input:checked ~ .custom-control-label::before { background-color: #1bb73f; }
.custom-checkbox.checkbox-warning .custom-control-input:checked ~ .custom-control-label::before { background-color: #ff9d21; }
.custom-checkbox.checkbox-danger .custom-control-input:checked ~ .custom-control-label::before { background-color: #dc3545; }

/*      Label      */
.col-form-label { line-height: 1.8; }
.required-label { color: red; }
.label-align-left { text-align: left; }
.label-align-right { text-align: right; }
.label-align-center { text-align: center; }

/*     Input    */
.form-group, .form-check {
  margin-bottom: 0;
  padding: 10px; 
}
.form-group label, .form-check label {
  margin-bottom: .5rem;
  color: #495057;
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap; 
}
.form-group-default {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
  overflow: hidden;
  width: 100%;
  -webkit-transition: background-color .2s ease;
  transition: background-color .2s ease;
  margin-bottom: 15px; 
}
.form-group-default label {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 0;
  display: block;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease; 
}
.form-group-default label:not(.error) {
  font-size: 10.5px !important;
  letter-spacing: .06em;
  text-transform: uppercase;
  font-weight: 400; 
}
.form-group-default .form-control {
  border: 0;
  min-height: 25px;
  padding: 0;
  margin-top: 6px;
  background: 0 0;
  font-size: 14px; 
}
.form-group-default select.form-control:not([size]):not([multiple]) { height: unset !important; }
.form-group-default.active {
  border-color: rgba(0, 0, 0, 0.1) !important;
  background-color: #f0f0f0; 
}
.form-group-default.active label { opacity: 0.5; }

.form-floating-label { position: relative; }
.form-floating-label .placeholder {
  position: absolute;
  padding: .375rem .75rem;
  transition: all .2s;
  opacity: 0.8;
  margin-bottom: 0 !important;
  font-size: 14px !important;
  font-weight: 400;
  top: 12px; 
}
.form-floating-label .form-control:focus + .placeholder, .form-floating-label .form-control:valid + .placeholder, .form-floating-label .form-control.filled + .placeholder {
  font-size: 85% !important;
  transform: translate3d(0, -10px, 0);
  top: 0;
  opacity: 1;
  padding: .375rem 0 .75rem;
  font-weight: 600; 
}
.form-floating-label .form-control.filled + .placeholder { color: #ff3030 !important; }
.form-floating-label .form-control ::-webkit-input-placeholder { color: transparent; }
.form-floating-label .form-control :-moz-placeholder { color: transparent; }
.form-floating-label .form-control ::-moz-placeholder { color: transparent; }
.form-floating-label .form-control :-ms-input-placeholder { color: transparent; }
.form-floating-label .input-border-bottom + .placeholder { padding: .375rem 0 .75rem; }

.form-inline label { margin-bottom: 0 !important; }
.input-fixed { width: 200px; }
.form-control.input-full { width: 100% !important; }
.has-success label { color: #1bb73f !important; }
.has-success .form-control {
  border-color: #1bb73f !important;
  color: #1bb73f !important; 
}
.has-success .input-group-text {
  border-color: #1bb73f !important;
  background: #1bb73f !important;
  color: #ffffff !important; 
}
.has-error label { color: #dc3545 !important; }
.has-error .form-control {
  border-color: #dc3545 !important;
  color: #dc3545 !important; 
}
.has-error .input-group-text {
  border-color: #dc3545 !important;
  background: #dc3545 !important;
  color: #ffffff !important; 
}
.input-group label.error, .input-group label.success {
  width: 100%;
  order: 100; 
}
.custom-control { position: relative; }
.custom-control.custom-radio, .custom-control.custom-checkbox {
  margin-bottom: 0;
  padding-left: 2em;
  cursor: pointer;
  line-height: 24px;
  margin-right: 25px;
  display: inline-block; 
}
.custom-control.custom-radio label.error, .custom-control.custom-radio label.success, .custom-control.custom-checkbox label.error, .custom-control.custom-checkbox label.success {
  position: absolute;
  width: 100vh;
  top: 23px;
  left: 0; 
}

.has-feedback { position: relative; }
.form-control-feedback {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px; 
}
.has-success .form-control-feedback { color: #1bb73f; }
.has-error .form-control-feedback { color: #dc3545; }
.input-group.has-icon {
  border-radius: .25rem;
  border: 1px solid #ced4da; 
}
.input-group.has-success, .input-group.has-icon.has-success {
  border: 1px solid #1bb73f !important;
  color: #1bb73f; 
}
.input-group.has-error {
  border: 1px solid #dc3545 !important;
  color: #dc3545; 
}
.input-group.has-icon.has-error {
  border: 1px solid #dc3545 !important;
  color: #dc3545; 
}
.input-group.has-icon .form-control {
  border-radius: .25rem;
  border: 0px;
  position: relative; 
}
.input-group.has-icon .input-group-icon {
  background: transparent;
  border: 0px; 
}
.input-square { border-radius: 0px !important; }
.input-pill { border-radius: 1.3rem !important; }
.input-solid {
  background: #e8e8e8 !important;
  border-color: #e8e8e8 !important; 
}
.input-border-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0px;
  padding: .75rem 0;
  background: transparent !important; 
}
div.required .control-label:not(span)::after, td.required::before {
    content: '';
    color: #F00;
    font-weight: bold;
}
.input-box .control-label {
  text-align: right;
  margin-bottom: 0;
  padding-top: 8px;
}
.table thead td span[data-toggle="tooltip"]::after, label.control-label span::after {
    font-family: FontAwesome;
    color: #1E91CF;
    content: "\f059";
    margin-left: 4px;
    
}
/*      Input File      */
.input-file input[type="file"], .input-file .form-control, .input-file .form-control-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; 
}
.input-file label.error, .input-file label.success { display: block; }
.input-file input[type="file"] + label:not(.error), .input-file .form-control + label:not(.error), .input-file .form-control-file + label:not(.error), .input-file .label-input-file {
  font-weight: 600;
  letter-spacing: 0.02em;
  color: white !important;
  display: inline-block; 
}
.input-file.input-file-image img.img-upload-preview {
  max-width: 100%;
  display: block;
  margin-bottom: 15px;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1); 
}
.input-file.input-file-image img.img-upload-preview.img-circle { border-radius: 2000px; }
.form-control:disabled, .form-control[readonly] {
  background: #e8e8e8 !important;
  border-color: #e8e8e8 !important; 
}
.form-control:disabled, .form-control[readonly] { opacity: 0.6 !important; }
.form-radio-label, .form-checkbox-label {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  font-weight: 400 !important;
  margin-left:85px;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
  color: #8d9498 !important
}
input[type="checkbox"], input[type="radio"] {
  margin-right: 3px;
}
/*    Input Group    */
.input-group-text { font-size: 14px; }
.input-group-text i.la { font-size: 21px; }
.input-group-text i[class*="flaticon"] { font-size: 17px; }

/*    Input Icon */
.input-icon { position: relative; }
.input-icon .form-control:not(:first-child) { padding-left: 2.5rem; }
.input-icon .form-control:not(:last-child) { padding-right: 2.5rem; }
.input-icon .input-icon-addon {
  position: absolute;
  left: 1rem;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center; 
}
.input-icon .input-icon-addon:last-child {
  left: auto;
  right: 1rem; 
}

.switch label { cursor: pointer; }
.switch label input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}
.switch label .lever {
    position: relative;
    display: inline-block;
    margin: 0 1rem;
    margin-right: .625rem;
    vertical-align: middle;
    content: "";
    background-color: #818181;
    border-radius: .9375rem;
    width: 2.5rem;
    height: .9375rem;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;
}
.input-box .switch label .lever {
    margin: 0 .625rem;
    background-color: #ccc;
    width: 44px;
    height: 24px;
    border-radius: 10em;
}
.input-box .switch label input[type="checkbox"]:checked + .lever {
    background-color: #1266f1;
}
.switch label .lever::after {
    position: absolute;
    top: -.1875rem;
    left: -.3125rem;
    display: inline-block;
    content: "";
    background-color: #f1f1f1;
    border-radius: 1.3125rem;
    -webkit-box-shadow: 0 .0625rem .1875rem .0625rem rgba(0,0,0,0.4);
    box-shadow: 0 .0625rem .1875rem .0625rem rgba(0,0,0,0.4);
    width: 1.3125rem;
    height: 1.3125rem;
    -webkit-transition: left .3s ease,background .3s ease,-webkit-box-shadow 1s ease;
    transition: left .3s ease,background .3s ease,-webkit-box-shadow 1s ease;
    transition: left .3s ease,background .3s ease,box-shadow 1s ease;
    transition: left .3s ease,background .3s ease,box-shadow 1s ease,-webkit-box-shadow 1s ease;
}
.input-box .switch label .lever::after {
    background-color: #fff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    left: 4px;
    top: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/*     Label States      */
label.error {
  color: #dc3545 !important;
  font-size: 80% !important;
  margin-top: .5rem; 
}

/*     Label states for select2      */
.select2-input { position: relative; }
.select2-input label.error, .select2-input label.success {
  position: absolute;
  bottom: -30px; 
}
.select2-input .select2 { margin-bottom: 15px; }

/*      Custom Dropzone      */
.dropzone {
  padding: 70px 60px 80px !important;
  border: 2px dashed rgba(0, 0, 0, 0.13) !important;
  background: transparent !important; 
}
.dropzone:hover {
  background: #f2f2f2 !important;
  transition: all .5s !important; 
}
.dropzone .dz-message .icon {
  margin-bottom: 15px;
  font-size: 39px; 
}
.dropzone .dz-message .message {
  font-size: 34px;
  font-weight: 200; 
}
.dropzone .dz-message .note {
  font-size: 16px;
  margin-top: 18px;
  font-weight: 300; 
}

/*      Custom Summernote     */
.note-editor.note-frame {
  border: 0px !important;
  box-shadow: none !important; 
  border: 1px solid #ebedf2 !important; 
}
.note-editor.note-frame .note-toolbar { border-bottom: 1px solid #ebedf2 !important; }
.note-editor.note-frame .note-btn {
  border: 1px solid #ebedf2 !important;
  background: #f2f2f2 !important;
  color: #1b2839 !important; 
}
.note-editor.note-frame .note-placeholder {  margin-top: 15px !important; }
.note-editor.note-frame .note-codable { margin-top: 15px !important; }
.note-editor.note-frame .note-editing-area .note-editable { margin-top: 15px !important; }
.note-editor.note-airframe .note-statusbar, .note-editor.note-frame .note-statusbar { border-top: none; }
.note-editor.note-frame .note-editing-area .note-editable p {
  margin: 0;
  line-height: 24px;
}
.note-editor .note-toolbar .dropdown-toggle:after , .note-popover .popover-content .dropdown-toggle:after { display: none; }

/*      Custom datepicker     */
.datepicker th { font-weight: 500; }
.datepicker td, .datepicker th {
  width: 35px;
  height: 35px;
  font-size: 14px;
}
.datepicker table tr td.day:hover, .datepicker table tr td.day.focused, .datepicker table tr td.active,
.datepicker table tr td.active:hover, .datepicker table tr td.active:hover:hover {
  background: #ff3030 !important;
  cursor: pointer;
  color: #fff !important;
}
.datepicker .datepicker-switch {
    text-transform: uppercase;
}
/*     Table    */
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  vertical-align: middle; 
}
.table > tbody > tr > td, .table > tbody > tr > th { padding: 8px; }
.table > tfoot > tr > td, .table > tfoot > tr > th { padding: 8px; }
.table thead th {
  border-bottom-width: 2px;
  font-weight: 600; 
}
.table td, .table th {
  font-size: 14px;
  border-top-width: 0px;
  border-bottom: 1px solid;
  border-color: #ebedf2 !important;
  padding: 0 20px 0 10px !important;
  height: 70px;
  vertical-align: middle !important; 
}
.table th { height: 45px; }
.table > tbody > tr > td {
  padding: 0 10px !important;
}
.img-fluid.img-thumbnail { max-width: 50px; }
#multi-filter-select.table > tfoot > tr > th:first-child select, #multi-filter-select.table > tfoot > tr > th:last-child select {
    display: none;
}
.transaction-details .table td, .transaction-details .table th {
  height: 48px;
}
table.dataTable tr td:first-child, table.dataTable tr th:first-child ,table tr td:first-child, table tr th:first-child {
  padding-left: 10px !important;
}
table.dataTable tr td:last-child, table.dataTable tr th:last-child, table tr td:last-child, table tr th:last-child {
  padding-right: 0 !important;
}
.order-details .table th {
    border-top-width: 1px;
}
.order-details table tr td {
  padding: 10px 15px !important;
}
.order-details table tr td:first-child, .order-details table tr th:first-child {
  padding-left: 10px !important;
}
.order-details table tr td:last-child, .order-details table tr th:last-child {
  padding-right: 10px !important;
}
table.dataTable thead th[aria-label="Image: activate to sort column ascending"]:after,
table.dataTable thead th[aria-label="Image: activate to sort column descending"]:after,
table.dataTable thead th[aria-label="Image: activate to sort column ascending"]:before,
table.dataTable thead th[aria-label="Image: activate to sort column descending"]:before,
table.dataTable thead th[aria-label="Action: activate to sort column ascending"]:after,
table.dataTable thead th[aria-label="Action: activate to sort column descending"]:after,
table.dataTable thead th[aria-label="Action: activate to sort column ascending"]:before,
table.dataTable thead th[aria-label="Action: activate to sort column descending"]:before,
table.dataTable thead th[aria-label="profile: activate to sort column ascending"]:after,
table.dataTable thead th[aria-label="profile: activate to sort column descending"]:after,
table.dataTable thead th[aria-label="profile: activate to sort column ascending"]:before,
table.dataTable thead th[aria-label="profile: activate to sort column descending"]:before  {
  display: none;
}

table.dataTable thead th[aria-label="Action: activate to sort column ascending"],
table.dataTable thead th[aria-label="Action: activate to sort column descending"],
table.dataTable thead th[aria-label="profile: activate to sort column ascending"],
table.dataTable thead th[aria-label="profile: activate to sort column descending"],
table.dataTable thead th[aria-label="Image: activate to sort column ascending"],
table.dataTable thead th[aria-label="Image: activate to sort column descending"]{
  padding-right: 0 !important;
}
/* table full-width */
.table-full-width {
  margin-left: -15px;
  margin-right: -15px; 
}

/* table bordered states */
.table-bordered-bd-default td, .table-bordered-bd-default th { border: 1px solid #1a2035 !important; }
.table-bordered-bd-primary td, .table-bordered-bd-primary th { border: 1px solid #ff3030 !important; }
.table-bordered-bd-secondary td, .table-bordered-bd-secondary th { border: 1px solid #0071e8 !important; }
.table-bordered-bd-info td, .table-bordered-bd-info th { border: 1px solid #48abf7 !important; }
.table-bordered-bd-success td, .table-bordered-bd-success th { border: 1px solid #1bb73f !important; }
.table-bordered-bd-warning td, .table-bordered-bd-warning th { border: 1px solid #ff9d21 !important; }
.table-bordered-bd-danger td, .table-bordered-bd-danger th { border: 1px solid #dc3545 !important; }
.table-striped td, .table-striped th {
  border-top: 0 !important;
  border-bottom: 0 !important; 
}

/* table head background states*/
.table-head-bg-default thead { border: 1px solid #1a2035 !important; }
.table-head-bg-primary thead { border: 1px solid #ff3030 !important; }
.table-head-bg-secondary thead { border: 1px solid #0071e8 !important; }
.table-head-bg-info thead { border: 1px solid #48abf7 !important; }
.table-head-bg-success thead { border: 1px solid #1bb73f !important; }
.table-head-bg-warning thead {  border: 1px solid #ff9d21 !important; }
.table-head-bg-danger thead { border: 1px solid #dc3545 !important; }
.table-head-bg-default thead th, .table-striped-bg-default tbody tr:nth-of-type(odd) {
  background: #1a2035 !important;
  color: #ffffff !important;
  border: 0px !important; 
}
.table-head-bg-primary thead th, .table-striped-bg-primary tbody tr:nth-of-type(odd) {
  background: #ff3030 !important;
  color: #ffffff !important;
  border: 0px !important; 
}
.table-head-bg-secondary thead th, .table-striped-bg-secondary tbody tr:nth-of-type(odd) {
  background: #0071e8 !important;
  color: #ffffff !important;
  border: 0px !important; 
}
.table-head-bg-info thead th, .table-striped-bg-info tbody tr:nth-of-type(odd) {
  background: #48abf7 !important;
  color: #ffffff !important;
  border: 0px !important; 
}
.table-head-bg-success thead th, .table-striped-bg-success tbody tr:nth-of-type(odd) {
  background: #1bb73f !important;
  color: #ffffff !important;
  border: 0px !important; 
}
.table-head-bg-warning thead th, .table-striped-bg-warning tbody tr:nth-of-type(odd) {
  background: #ff9d21 !important;
  color: #ffffff !important;
  border: 0px !important; 
}
.table-head-bg-danger thead th, .table-striped-bg-danger tbody tr:nth-of-type(odd) {
  background: #dc3545 !important;
  color: #ffffff !important;
  border: 0px !important; 
}
.table-hover tbody tr:hover { background-color: rgba(218, 228, 251, 0.5); }
/* table-responsive */
.table-responsive {
  width: 100% !important;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; 
}

/*     Navbar     */
.navbar .navbar-nav .nav-item { margin-right: 7px; }
.navbar .navbar-nav .nav-item:last-child { margin-right: 0px; }
.navbar .navbar-nav .nav-item .nav-link {
  background: #dd0202 !important;
  display: inline-block;
  vertical-align: middle;
  color: #ffffff;
  letter-spacing: 0.04em;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  text-align: center; 
}
.navbar .navbar-nav .nav-item .nav-link:hover, .navbar .navbar-nav .nav-item .nav-link:focus {
  background: #dd0202 !important; 
}
.navbar .navbar-nav .nav-item .nav-link i {
  font-size: 16px;
  vertical-align: middle;
}
.navbar .navbar-nav .nav-item.active .nav-link {
  background: #eee !important; 
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  left: auto;
  right: 0; 
}
.dropdown-item { font-size: 13px; }
.navbar .navbar-nav .notification {
  position: absolute;
  background-color: #1bb73f;
  text-align: center;
  border-radius: 10px;
  min-width: 17px;
  height: 17px;
  font-size: 10px;
  color: #ffffff;
  line-height: 17px;
  top: 3px;
  right: 3px;
  letter-spacing: -1px; 
}
.navbar-header { padding: 0px 15px; }
.navbar-header .dropdown-toggle::after { margin-left: 0; }
.profile-pic:hover, .profile-pic:focus { text-decoration: none; }
.navbar-header .dropdown-toggle::after {
  vertical-align: middle;
  color: #555; 
  display: none;
}
.hidden-caret .dropdown-toggle::after { display: none !important; }
.profile-pic span {
  font-size: 13px;
  font-weight: 300;
  padding: 0 10px;
  color: #555; 
}

.navbar[class*="bg-"] {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.1) !important; 
}
.navbar[class*="bg-"] .navbar-brand { color: #ffffff; }
.navbar[class*="bg-"] .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); 
}
.navbar[class*="bg-"] .navbar-nav > .nav-item > .nav-link { color: #ffffff; }
.navbar[class*="bg-"] .navbar-nav > .nav-item > .nav-link.disabled { color: #d8d8d8 !important; }
.navbar[class*="bg-"] .navbar-nav > .nav-item > .nav-link:hover { background: rgba(255, 255, 255, 0.22) !important; }
.navbar[class*="bg-"] .navbar-nav > .nav-item.active > .nav-link { background: rgba(255, 255, 255, 0.22) !important; }
.navbar[class*="bg-"] .btn-toggle {
  background: rgba(19, 19, 19, 0.25) !important;
  color: #ffffff !important; 
}
.navbar[class*="bg-"] .nav-search .input-group {
  border: 0;
  background: rgba(19, 19, 19, 0.25) !important; 
}
.navbar[class*="bg-"] .nav-search .input-group .form-control { color: #ffffff !important; }
.navbar[class*="bg-"] .nav-search .search-icon { color: #ffffff !important; }

.row-nav-line {
  margin-left: -20px;
  margin-right: -20px; 
}

.nav.nav-line {
  width: 100%;
  border-bottom: 1px solid #f1f1f1; 
}
.nav.nav-line .nav-link {
  padding: 15px 20px;
  color: #666666;
  border-width: 0px;
  font-size: 14px;
  font-weight: 600; 
}
.nav.nav-line .nav-link:hover, .nav.nav-line .nav-link:focus {
  color: #ff3030;
  border-width: 0px; 
}
.nav.nav-line .nav-link.active {
  border-width: 0;
  background-color: transparent;
  color: #ff3030;
  border-bottom: 2px solid #ff3030;
  border-radius: 0px; 
}
.nav.nav-line.nav-color-default .nav-link:hover, .nav.nav-line.nav-color-default .nav-link:focus { color: #1a2035; }
.nav.nav-line.nav-color-default .nav-link.active {
  color: #1a2035;
  border-color: #1a2035; 
}
.nav.nav-line.nav-color-primary .nav-link:hover, .nav.nav-line.nav-color-primary .nav-link:focus { color: #ff3030; }
.nav.nav-line.nav-color-primary .nav-link.active {
  color: #ff3030;
  border-color: #ff3030; 
}
.nav.nav-line.nav-color-secondary .nav-link:hover, .nav.nav-line.nav-color-secondary .nav-link:focus { color: #0071e8; }
.nav.nav-line.nav-color-secondary .nav-link.active {
  color: #0071e8;
  border-color: #0071e8; 
}
.nav.nav-line.nav-color-info .nav-link:hover, .nav.nav-line.nav-color-info .nav-link:focus { color: #48abf7; }
.nav.nav-line.nav-color-info .nav-link.active {
  color: #48abf7;
  border-color: #48abf7; 
}
.nav.nav-line.nav-color-success .nav-link:hover, .nav.nav-line.nav-color-success .nav-link:focus { color: #1bb73f; }
.nav.nav-line.nav-color-success .nav-link.active {
  color: #1bb73f;
  border-color: #1bb73f; 
}
.nav.nav-line.nav-color-danger .nav-link:hover, .nav.nav-line.nav-color-danger .nav-link:focus { color: #dc3545; }
.nav.nav-line.nav-color-danger .nav-link.active {
  color: #dc3545;
  border-color: #dc3545; 
}
.nav.nav-line.nav-color-warning .nav-link:hover, .nav.nav-line.nav-color-warning .nav-link:focus { color: #ff9d21; }
.nav.nav-line.nav-color-warning .nav-link.active {
  color: #ff9d21;
  border-color: #ff9d21; 
}
.nav.nav-line.nav-color-light .nav-link:hover, .nav.nav-line.nav-color-light .nav-link:focus { color: #ffffff; }
.nav.nav-line.nav-color-light .nav-link.active {
  color: #ffffff;
  border-color: #ffffff; 
}
.nav.nav-line.nav-color-light .nav-link { color: #f1f1f1; }

/*     Nav Search     */
.nav-search .input-group {
  border-radius: 5px; 
}
.nav-search .form-control {
  border: 0;
  background: #ffffff !important;
  font-size: 14px;
  padding: 0.75em 1em;
  min-width: 200px;
  max-width: 100%; 
  height: 40px !important;
  border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -webkit-border-radius: 25px !important;
  -o-border-radius: 25px !important;
}
.nav-search .input-group-text {
  border: 0;
  background: transparent; 
}
.nav-search .search-icon {
  font-size: 18px;
  color: #ffffff; 
}
.nav-search .btn-search {
  background: #dd0202;
  position: absolute;
  right: 0;
  padding: 0 !important;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -webkit-border-radius: 25px !important;
  -o-border-radius: 25px !important;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .input-group > .custom-select:focus, .input-group > .form-control:focus {
    z-index: 0;
}
/*     Badge    */
.badge {
  border-radius: 50px;
  margin-left: auto;
  line-height: 1;
  padding: 5px 10px;
  vertical-align: middle;
  font-weight: 500;
  border: 1px solid #ddd; 
}

[class*="badge-"]:not(.badge-count) { border: 0px !important; }

.badge-default { background: #1a2035; }
.badge-primary { background: #ff3030; }
.badge-secondary { background: #13c9ca; }
.badge-info { background: #17a2b8; }
.badge-success { background-color: #1bb73f; }
.badge-warning { background: #ff9d21; color: #ffffff; }
.badge-danger { background-color: #dc3545; }
.badge-dark { background-color: #6c757d; }

/*     Dropdown    */
.dropdown-menu {
  border: 0px;
  border-radius: 3px;
  box-shadow: 0 1px 11px rgba(0, 0, 0, 0.15) !important;
  padding-bottom: 8px;
  margin-top: 3px; 
}

/*     Notification dropdown    */
.dropdown-title {
  border-bottom: 1px solid #f1f1f1;
  color: #444444;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 15px;
  text-align: center; 
}

.notif-box, .messages-notif-box {
  width: 280px;
  padding: 0 !important; 
}
.notif-box .notif-center a, .messages-notif-box .notif-center a {
  display: flex;
  color: #4d585f; 
}
.notif-box .notif-center a:hover, .messages-notif-box .notif-center a:hover {
  text-decoration: none;
  background: #f2f2f2;
  transition: all .2s; 
}
.notif-box .notif-center a .notif-icon, .messages-notif-box .notif-center a .notif-icon {
  display: inline-flex;
  width: 40px;
  height: 40px;
  margin: 10px;
  align-items: center;
  justify-content: center;
  background: #eee;
  border-radius: 50%; 
}
.notif-box .notif-center a .notif-img, .messages-notif-box .notif-center a .notif-img {
  display: inline-flex;
  width: 40px;
  height: 40px;
  margin: 10px;
  align-items: center;
  justify-content: center;
  background: #eee;
  border-radius: 50%; 
}
.notif-box .notif-center a .notif-img img, .messages-notif-box .notif-center a .notif-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%; 
}
.notif-box .notif-center a .notif-content .subject, .messages-notif-box .notif-center a .notif-content .subject {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin-bottom: 2px; 
}
.notif-box .notif-center a .notif-content .block, .messages-notif-box .notif-center a .notif-content .block {
  font-size: 13px;
  line-height: 20px;
  display: block; 
}
.notif-box .notif-center a .notif-content .time, .messages-notif-box .notif-center a .notif-content .time {
  color: #7d8c95;
  font-size: 11px; 
}
.notif-box .see-all, .messages-notif-box .see-all {
  border-top: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  color: #555;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none; 
}
.notif-box .see-all:hover, .messages-notif-box .see-all:hover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  color: #555;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none; 
}
.notif-box .see-all i, .messages-notif-box .see-all i { float: right; }
.notif-box .notif-scroll { max-height: 256px; }
.messages-notif-box .message-notif-scroll { max-height: 250px; }
.messages-notif-box .notif-center a { border-bottom: 1px solid #f1f1f1; }
.messages-notif-box .notif-center a:last-child { border-bottom: 0px; }
.messages-notif-box .notif-center a .notif-content { padding: 7px 15px 7px 5px; }

/*     User Dropdown    */
.dropdown-user { width: 270px; }
.user-box {
  display: flex;
  padding: 5px 12px; 
}
.user-box .user-detail {  padding: 0 10px; }
.user-box .user-detail h4 {
  margin-bottom: 5px;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1; 
}
.user-box .user-detail .text-muted {
  font-size: 12px;
  margin-bottom: 5px; 
}
.user-box .user-detail .btn { font-size: 11px; }
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; 
  font-size: 20px;
  color: #B5B5C3;
}
.dropdown-item .fas {
    width: 20px;
    height: 16px;
}
.dropdown-item.active, .dropdown-item:active  { background-color: transparent ; }
.dropdown-item:hover { 
  background-color: #f2f2f2; 
  color: #ff3030;
}
/* Quick Actions Dropdown */
.quick-actions {
  width: 350px;
  padding: 0px; 
}
.quick-actions:after { border-bottom-color: #ff3030 !important; }
.quick-actions .quick-actions-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ff3030;
  color: #fff;
  padding: 15px;
  border-radius: 3px 3px 0 0; 
}
.quick-actions .quick-actions-header .title { font-size: 18px; }
.quick-actions .quick-actions-header .subtitle { font-size: 13px; }
.quick-actions .quick-actions-items { padding: 15px 7.5px; }
.quick-actions a:hover { text-decoration: none; }
.quick-actions .quick-actions-item {
  display: flex;
  flex-direction: column;
  margin: 7.5px 7.5px;
  padding: 10px 5px;
  align-items: center;
  color: #ff3030;
  border: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  border-radius: 3px;
  transition: all .2s; 
}
.quick-actions .quick-actions-item:hover {
  background: #fdfdff;
  color: #ff3030;
  box-shadow: 3px 3px 10px #f7f1f1; 
}
.quick-actions .quick-actions-item i {
  color: #7d8c95;
  font-size: 27px; 
}
.quick-actions .quick-actions-item .text {
  text-align: center;
  font-size: 13px;
  margin-top: 8px; 
}
.quick-actions.quick-actions-default:after { border-bottom-color: #1a2035 !important; }
.quick-actions.quick-actions-default .quick-actions-header { background: #1a2035; }
.quick-actions.quick-actions-default .quick-actions-item { color: #1a2035; }

.quick-actions.quick-actions-primary:after { border-bottom-color: #ff3030 !important; }
.quick-actions.quick-actions-primary .quick-actions-header { background: #ff3030; }
.quick-actions.quick-actions-primary .quick-actions-item { color: #ff3030; }

.quick-actions.quick-actions-secondary:after { border-bottom-color: #0071e8 !important; }
.quick-actions.quick-actions-secondary .quick-actions-header { background: #0071e8; }
.quick-actions.quick-actions-secondary .quick-actions-item { color: #0071e8; }

.quick-actions.quick-actions-info:after { border-bottom-color: #DD0202 !important; }
.quick-actions.quick-actions-info .quick-actions-header { background: #DD0202; }
.quick-actions.quick-actions-info .quick-actions-item { color: #DD0202; }

.quick-actions.quick-actions-warning:after { border-bottom-color: #ff9d21 !important; }
.quick-actions.quick-actions-warning .quick-actions-header { background: #ff9d21; }
.quick-actions.quick-actions-warning .quick-actions-item { color: #ff9d21; }

.quick-actions.quick-actions-success:after { border-bottom-color: #1bb73f !important; }
.quick-actions.quick-actions-success .quick-actions-header { background: #1bb73f; }
.quick-actions.quick-actions-success .quick-actions-item { color: #1bb73f; }

.quick-actions.quick-actions-danger:after { border-bottom-color: #dc3545 !important; }
.quick-actions.quick-actions-danger .quick-actions-header { background: #dc3545; }
.quick-actions.quick-actions-danger .quick-actions-item { color: #dc3545; }

@media screen and (max-width: 991px) {
  .notif-box .notif-scroll, .messages-notif-box .message-notif-scroll, .quick-actions .quick-actions-scroll {
    max-height: calc(100vh - 200px); 
  }
  .dropdown-user .dropdown-user-scroll {
    max-height: calc(100vh - 132px); 
  } 
}

@media screen and (min-width: 991px) {
  .navbar-header .dropdown-menu { margin-top: 13px; }
  .navbar-header .dropdown-menu:after {
    border-bottom: 8px solid #fff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    content: "";
    right: 10px;
    top: -8px;
    position: absolute;
    z-index: 1001;
  } 
}

/*     Chart JS Container    */
.chart-container {
  min-height: 300px;
  position: relative; 
}

/*     HTML legend    */
.html-legend {
  list-style: none;
  cursor: pointer;
  padding-left: 0;
  text-align: center;
}

.html-legend li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px;
  margin-right: 5px;
  color: #8d9498;
  font-size: 12px; 
}

.html-legend li.hidden { text-decoration: line-through; }

.html-legend li span {
  border-radius: 5px;
  display: inline-block;
  height: 15px;
  margin-right: 5px;
  width: 15px;
  vertical-align: top; 
}
.jqstooltip { box-sizing: content-box; }

/*     Alert    */
.alert {
  border: 0px;
  position: relative;
  padding: .95rem 1.25rem;
  border-radius: 1px;
  color: inherit;
  background-color: #ffffff;
  -webkit-box-shadow: 1px 1px 14px 0px rgba(18, 38, 63, 0.26);
  -moz-box-shadow: 1px 1px 14px 0px rgba(18, 38, 63, 0.26);
  box-shadow: 1px 1px 14px 0px rgba(18, 38, 63, 0.26); 
}
.alert [data-notify="icon"] { display: block; }
.alert [data-notify="icon"]::before {
  line-height: 35px;
  font-size: 22px;
  display: block;
  left: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  border-radius: 30px;
  text-align: center;
  color: #fff; 
}
.alert [data-notify="title"] {
  display: block;
  color: #2b2b2b;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 5px; 
}
.alert [data-notify="message"] {
  font-size: 13px;
  color: #908e8e; 
}
.alert .close {
  background: rgba(255, 255, 255, 0.8);
  width: 25px;
  height: 25px;
  line-height: 25px;
  top: 12px !important;
  border-radius: 50%; 
}

/*    Alert States    */
.alert-default {  border-left: 4px solid #1a2035; }
  .alert-default [data-notify="icon"]:before { background: #1a2035; }

.alert-primary { border-left: 4px solid #ff3030; }
  .alert-primary [data-notify="icon"]:before { background: #ff3030; }

.alert-secondary { border-left: 4px solid #0071e8; }
  .alert-secondary [data-notify="icon"]:before { background: #0071e8; }

.alert-info { border-left: 4px solid #48abf7; }
  .alert-info [data-notify="icon"]:before { background: #48abf7; }

.alert-success { border-left: 4px solid #1bb73f; }
  .alert-success [data-notify="icon"]:before { background: #1bb73f; }

.alert-warning { border-left: 4px solid #ff9d21; }
  .alert-warning [data-notify="icon"]:before { background: #ff9d21; }

.alert-danger { border-left: 4px solid #dc3545; }
  .alert-danger [data-notify="icon"]:before { background: #dc3545; }

/*    Button    */
.btn {
  padding:5px;
  font-size: 20px;
  opacity: 1;
  border-radius: 3px; 
}
.btn .btn-label { display: inline-block; }
.btn .btn-label i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 2px;
  margin-left: -2px;
  line-height: 0;
  margin-top: -2.5px; 
}
.btn .btn-label.just-icon i {
  margin-left: 0 !important;
  margin-right: 0px !important; 
}

/*     Button Sizes     */
.btn-lg {
  font-size: 15px;
  border-radius: 3px;
  padding: 12.5px 27.5px;
  font-weight: 400; 
}
.btn-lg .btn-label i {
  font-size: 27px;
  vertical-align: middle;
  margin-right: 2px;
  margin-left: -7px;
  line-height: 0;
  margin-top: -2.5px; 
}
.btn-lg .btn-label.just-icon i {
  margin-left: 0 !important;
  margin-right: 0px !important; 
}

.btn-sm {
  font-size: 11px;
  padding: 7px 13px; 
}
.btn-xs {
  font-size: 10px;
  padding: 5px 9px; 
}

/*      Button Icon        */
.btn-icon {
  font-size: .9375rem;
  height: 2.5125rem;
  line-height: normal;
  min-width: 2.5125rem;
  overflow: hidden;
  padding: 0 !important;
  position: relative;
  width: 2.5125rem; 
}
.btn-icon.btn-lg {
  height: 2.75rem;
  min-width: 2.75rem;
  width: 2.75rem; 
}
.btn-icon.btn-sm {
  height: 2rem;
  min-width: 2rem;
  width: 2rem; 
}
.btn-icon.btn-xs {
  height: 1.6875rem;
  min-width: 1.6875rem;
  width: 1.6875rem; 
}
.order-details .btn.btn-primary.btn-xs {
    width: 30px;
    height: 30px;
    text-align: center;
}
/*      Button States      */
.btn-white {
  background: #ffffff !important;
  color: #1a2035 !important; 
}
  .btn-white:hover, .btn-white:focus, .btn-white:disabled {
    background: #ffffff !important;
    color: #1a2035 !important; 
  }

.btn-default {
  background: #1a2035 !important;
  color: #ffffff !important; 
}
  .btn-default:hover, .btn-default:focus, .btn-default:disabled {
    background: #1a2035 !important;
    color: #ffffff !important; 
  }

.btn-primary {
  background:#ffffff!important;
  /* border-color: #ff3030 !important;  */
  border: none;
  color: #000;
}
  .btn-primary:hover, .btn-primary:focus, .btn-primary:disabled {
    background:#eee0e0!important;
    color: rgb(0, 0, 0);
    /* border-color: #ffffff !important;  */
    
  }

.btn-secondary {
  background: #0071e8 !important;
  border-color: #0071e8 !important; 
}
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:disabled {
    background: #0071e8 !important;
    border-color: #0071e8 !important; 
  }

.btn-info {
  background: #48abf7 !important;
  border-color: #48abf7 !important; 
}
  .btn-info:hover, .btn-info:focus, .btn-info:disabled {
    background: #48abf7 !important;
    border-color: #48abf7 !important; 
  }

.btn-success {
  background: #1bb73f !important;
  border-color: #1bb73f !important; 
}
  .btn-success:hover, .btn-success:focus, .btn-success:disabled {
    background: #1bb73f !important;
    border-color: #1bb73f !important; 
  }

.btn-warning {
  background: #ff9d21 !important;
  border-color: #ff9d21 !important;
  color: #ffffff !important; 
}
  .btn-warning:hover, .btn-warning:focus, .btn-warning:disabled {
    background: #ff9d21 !important;
    border-color: #ff9d21 !important;
    color: #ffffff !important; 
  }

.btn-danger {
  background: #dc3545 !important;
  border-color: #dc3545 !important; 
}
  .btn-danger:hover, .btn-danger:focus, .btn-danger:disabled {
    background: #dc3545 !important;
    border-color: #dc3545 !important; 
  }

.btn-light {
  background: #ffffff !important;
  border-color: transparent; 
}
.btn-light:hover, .btn-light:focus, .btn-light:disabled {
  background: #ebecec !important;
  border-color: transparent; 
}
.btn-dropdown-card-header {
  padding: 0;
  background: transparent;
  color: inherit;
  font-size: 15px; 
}
  .btn-dropdown-card-header:after { display: none; }

/*      Button Border     */
.btn-border { background: transparent !important; }
.btn-border:hover, .btn-border:focus { background: transparent !important; }
.btn-border.btn-white {
  color: #ffffff !important;
  border: 1px solid #ffffff !important; 
}
.btn-border.btn-default {
  color: #1a2035 !important;
  border: 1px solid #1a2035 !important; 
}
.btn-border.btn-primary {
  color: #ff3030 !important;
  border: 1px solid #ff3030 !important; 
}
.btn-border.btn-secondary {
  color: #0071e8 !important;
  border: 1px solid #0071e8 !important; 
}
.btn-border.btn-info {
  color: #48abf7 !important;
  border: 1px solid #48abf7 !important; 
}
.btn-border.btn-success {
  color: #1bb73f !important;
  border: 1px solid #1bb73f !important; 
}
.btn-border.btn-warning {
  color: #ff9d21 !important;
  border: 1px solid #ff9d21 !important; 
}
.btn-border.btn-danger {
  color: #dc3545 !important;
  border: 1px solid #dc3545 !important; 
}
.btn-border.btn-light {
  border: 1px solid #efefef;
  background: #fff !important; 
}

/*      Button Rounded      */
.btn-round { border-radius: 100px !important; }

/*      Button Link      */
.btn-link {
  border: 0 !important;
  background: transparent !important; 
}
.btn-link:hover, .btn-link:focus {
  text-decoration: underline !important;
  background: transparent !important;
  border: 0 !important; 
}
.btn-link.btn-default { color: #1a2035 !important; }
.btn-link.btn-default:hover { color: #1a2035 !important; }

.btn-link.btn-primary { color: #ff3030 !important; }
.btn-link.btn-primary:hover { color: #ff3030 !important; }

.btn-link.btn-secondary { color: #0071e8 !important; }
.btn-link.btn-secondary:hover { color: #0071e8 !important; }

.btn-link.btn-info { color: #48abf7 !important; }
.btn-link.btn-info:hover { color: #48abf7 !important; }

.btn-link.btn-success { color: #1bb73f !important; }
.btn-link.btn-success:hover { color: #1bb73f !important; }

.btn-link.btn-warning { color: #ff9d21 !important; }
.btn-link.btn-warning:hover { color: #ff9d21 !important; }

.btn-link.btn-danger { color: #dc3545 !important; }
.btn-link.btn-danger:hover { color: #dc3545 !important; }

.toggle-on.btn { color: #ffffff !important; }

.toggle-handle { background: #ffffff !important; }
.toggle-handle:hover { background: #ffffff !important; }

.btn-round .toggle-handle { border-radius: 50px; }
.btn-rounded { border-radius: 60px !important; }
.btn-full { width: 100%; }
.btn-no-radius { border-radius: 0px; }

/*     Nav Pill     */
.nav.nav-pills {
    border-bottom: 1px solid #ebedf2;
    margin-bottom: 15px;
}
.nav-link.disabled { color: #6c757d !important; }
.nav-pills .nav-link { padding: 10px 20px; }
.nav-pills > li > .nav-link {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 15px;
  border: none !important;
  color: #585c5d; 
    border-radius : 25px !important;
    -moz-border-radius : 25px !important;
    -webkit-border-radius : 25px !important;
    -o-border-radius : 25px !important;
}
.nav-pills > li > .nav-link.active { background: #ff3030; }
.nav-pills.nav-pills-no-bd li { margin-left: 15px !important; }
.nav-pills.nav-pills-no-bd li .nav-link {
  border: 0px !important;
  border-radius: 50px !important;
  background: rgba(222, 222, 222, 0.4); 
}
.nav-pills.nav-pills-no-bd li .nav-link.active { border-radius: 50px !important; }
.nav-pills.nav-pills-no-bd li:first-child { margin-left: 0px !important; }
.nav-pills.nav-pills-no-bd.nav-pills-icons .nav-link, .nav-pills.nav-pills-no-bd.nav-pills-icons .nav-link.active { border-radius: 5px !important; }
.nav-pills.flex-column .nav-link {
  border-radius: 0 !important;
  border: 1px solid #ff3030;
  color: #585c5d;
  margin-top: -1px;
  text-align: center;
  word-wrap: normal;
  padding: 10px 0; 
}
.nav-pills.flex-column .nav-link:hover { background: rgba(222, 222, 222, 0.4); }
.nav-pills.flex-column .nav-link.active { background: #ff3030; }
.nav-pills.flex-column .nav-link:first-child { border-radius: 4px 4px 0 0 !important; }
.nav-pills.flex-column .nav-link:last-child { border-radius: 0 0 4px 4px !important; }
.nav-pills.flex-column.nav-pills-no-bd .nav-link {
  border: 0px !important;
  border-radius: 50px !important;
  background: rgba(222, 222, 222, 0.4);
  margin-top: 5px;
  margin-bottom: 5px; 
}
  .nav-pills.flex-column.nav-pills-no-bd .nav-link.active { border-radius: 50px !important; }
.nav-pills.flex-column.nav-pills-icons .nav-link, .nav-pills.flex-column.nav-pills-icons .nav-link.active { border-radius: 5px !important; }
.nav-pills.nav-pills-icons .nav-link, .nav-pills.nav-pills-icons .nav-link.active {
  border-radius: 5px !important;
  padding-top: 12px;
  padding-bottom: 12px; 
}
.nav-pills.nav-pills-icons i {
  display: block;
  text-align: center;
  font-size: 2em;
  line-height: 50px; 
}
.nav-pills.nav-default .nav-link, .nav-pills.nav-primary .nav-link, .nav-pills.nav-secondary .nav-link, .nav-pills.nav-info .nav-link, .nav-pills.nav-warning .nav-link, .nav-pills.nav-success .nav-link, .nav-pills.nav-danger .nav-link {
  border: 1px solid #eeeeee; 
}
.nav-pills.nav-default .nav-link.active, .nav-pills.nav-primary .nav-link.active, .nav-pills.nav-secondary .nav-link.active, .nav-pills.nav-info .nav-link.active, .nav-pills.nav-warning .nav-link.active, .nav-pills.nav-success .nav-link.active, .nav-pills.nav-danger .nav-link.active {
  color: #ffffff !important; 
}
.nav-pills.nav-default .nav-link.active {
  background: #1a2035;
  border: 1px solid #1a2035; 
}
.nav-pills.nav-primary .nav-link.active {
  background: #ff3030;
  border: 1px solid #ff3030; 
}
.nav-pills.nav-secondary .nav-link.active {
  background: #0071e8;
  border: 1px solid #0071e8; 
}
.nav-pills.nav-info .nav-link.active {
  background: #48abf7;
  border: 1px solid #48abf7; 
}
.nav-pills.nav-success .nav-link.active {
  background: #1bb73f;
  border: 1px solid #1bb73f; 
}
.nav-pills.nav-warning .nav-link.active {
  background: #ff9d21;
  border: 1px solid #ff9d21; 
}
.nav-pills.nav-danger .nav-link.active {
  background: #dc3545;
  border: 1px solid #dc3545; 
}

/* Size Nav */
.nav-sm .nav-link {
  font-size: 11px !important;
  padding: 8px 16px !important; 
}

/*     	Popover     */
.popover {
  max-width: 240px;
  line-height: 1.7;
  border: 0;
  box-shadow: 0px 0px 20px 1px rgba(69, 65, 78, 0.2); 
}
.popover .popover-header {
  background: transparent;
  font-size: 14px;
  border-bottom: 0px;
  text-transform: capitalize;
  margin-top: 5px;
  color: #aaaaaa;
  font-weight: 400; 
}
.popover .popover-body { margin-bottom: 5px; }
.popover .popover-body p {
  font-size: 13px;
  margin-bottom: 1rem; 
}
.popover.bs-popover-top .arrow:before, .popover.bs-popover-bottom .arrow:before, .popover.bs-popover-left .arrow:before, .popover.bs-popover-right .arrow:before {
  border: transparent; 
}
.popover.bs-popover-auto[x-placement^=right], .popover.bs-popover-right { margin-left: 10px; }
.popover.bs-popover-auto[x-placement^=left], .popover.bs-popover-left { margin-right: 10px; }
.popover.bs-popover-auto[x-placement^=top], .popover.bs-popover-top { margin-bottom: 10px; }
.popover.bs-popover-auto[x-placement^=bottom], .popover.bs-popover-bottom { margin-top: 10px; }

/*     	Progress     */
.progress {
  border-radius: 100px;
  height: 14px; 
}
  .progress .progress-bar { border-radius: 100px; }
  .progress.progress-sm { height: 8px; }
  .progress.progress-lg { height: 20px; }

/*     Pagination     */
.pagination > li > a, .pagination > li:first-child > a, .pagination > li:last-child > a, .pagination > li > span, .pagination > li:first-child > span, .pagination > li:last-child > span {
  border-radius: 100px !important;
  margin: 0 2px;
  color: #777777;
  border-color: #ddd; 
}
.pagination.pg-default > li.active > a, .pagination.pg-default > li.active:first-child > a, .pagination.pg-default > li.active:last-child > a, .pagination.pg-default > li.active > span, .pagination.pg-default > li.active:first-child > span, .pagination.pg-default > li.active:last-child > span {
  background: #1a2035;
  border-color: #1a2035;
  color: #ffffff; 
}
.pagination.pg-primary > li.active > a, .pagination.pg-primary > li.active:first-child > a, .pagination.pg-primary > li.active:last-child > a, .pagination.pg-primary > li.active > span, .pagination.pg-primary > li.active:first-child > span, .pagination.pg-primary > li.active:last-child > span {
  background: #ff3030;
  border-color: #ff3030;
  color: #ffffff; 
}
.pagination.pg-secondary > li.active > a, .pagination.pg-secondary > li.active:first-child > a, .pagination.pg-secondary > li.active:last-child > a, .pagination.pg-secondary > li.active > span, .pagination.pg-secondary > li.active:first-child > span, .pagination.pg-secondary > li.active:last-child > span {
  background: #0071e8;
  border-color: #0071e8;
  color: #ffffff; 
}
.pagination.pg-info > li.active > a, .pagination.pg-info > li.active:first-child > a, .pagination.pg-info > li.active:last-child > a, .pagination.pg-info > li.active > span, .pagination.pg-info > li.active:first-child > span, .pagination.pg-info > li.active:last-child > span {
  background: #48abf7;
  border-color: #48abf7;
  color: #ffffff; 
}
.pagination.pg-success > li.active > a, .pagination.pg-success > li.active:first-child > a, .pagination.pg-success > li.active:last-child > a, .pagination.pg-success > li.active > span, .pagination.pg-success > li.active:first-child > span, .pagination.pg-success > li.active:last-child > span {
  background: #1bb73f;
  border-color: #1bb73f;
  color: #ffffff; 
}
.pagination.pg-warning > li.active > a, .pagination.pg-warning > li.active:first-child > a, .pagination.pg-warning > li.active:last-child > a, .pagination.pg-warning > li.active > span, .pagination.pg-warning > li.active:first-child > span, .pagination.pg-warning > li.active:last-child > span {
  background: #ff9d21;
  border-color: #ff9d21;
  color: #ffffff; 
}
.pagination.pg-danger > li.active > a, .pagination.pg-danger > li.active:first-child > a, .pagination.pg-danger > li.active:last-child > a, .pagination.pg-danger > li.active > span, .pagination.pg-danger > li.active:first-child > span, .pagination.pg-danger > li.active:last-child > span {
  background: #dc3545;
  border-color: #dc3545;
  color: #ffffff; 
}

/*     Slider     */
.slider-default .ui-slider-range { background: #1a2035; }
.slider-primary .ui-slider-range { background: #ff3030; }
.slider-secondary .ui-slider-range { background: #0071e8; }
.slider-info .ui-slider-range { background: #48abf7; }
.slider-success .ui-slider-range { background: #1bb73f; }
.slider-warning .ui-slider-range { background: #ff9d21; }
.slider-danger .ui-slider-range { background: #dc3545; }

/*     	Modal     */
.modal .bg-default .modal-title, .modal .bg-primary .modal-title, .modal .bg-secondary .modal-title, .modal .bg-info .modal-title, .modal .bg-success .modal-title, .modal .bg-warning .modal-title, .modal .bg-danger .modal-title {
  color: #ffffff !important; 
}
.modal-content {
  border-radius: .4rem !important;
  border: 0 !important; 
}

.activity-feed {
  padding: 15px;
  list-style: none; 
}
.activity-feed .feed-item {
  position: relative;
  padding-bottom: 20px;
  padding-left: 30px;
  border-left: 2px solid #e1e1e1; 
}
.activity-feed .feed-item:last-child { border-color: transparent; }
.activity-feed .feed-item::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #177dff; 
}

.feed-item-default::after { background: #1a2035 !important; }
.feed-item-primary::after { background: #ff3030 !important; }
.feed-item-secondary::after { background: #0071e8 !important; }
.feed-item-success::after { background: #1bb73f !important; }
.feed-item-danger::after { background: #dc3545 !important; }
.feed-item-info::after { background: #48abf7 !important; }
.feed-item-warning::after { background: #ff9d21 !important; }
.activity-feed .feed-item .date {
  display: block;
  position: relative;
  top: -5px;
  color: #8c96a3;
  text-transform: uppercase;
  font-size: 13px; 
}
.activity-feed .feed-item .text {
  position: relative;
  top: -3px; 
}

/*      Timeline     */
.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative; 
}
.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eeeeee;
  left: 50%;
  margin-left: -1.5px; 
}
.timeline > li {
  margin-bottom: 20px;
  position: relative; 
}
.timeline > li:before {
  content: " ";
  display: table; 
}
.timeline > li:after {
  content: " ";
  display: table;
  clear: both; 
}
.timeline > li:before {
  content: " ";
  display: table; 
}
.timeline > li:after {
  content: " ";
  display: table;
  clear: both; 
}
.timeline > li > .timeline-panel {
  width: 50%;
  float: left;
  border: 1px solid #eeeeee;
  background: #ffffff;
  border-radius: 3px;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0px 1px 20px 1px rgba(69, 65, 78, 0.06);
  -moz-box-shadow: 0px 1px 20px 1px rgba(69, 65, 78, 0.06);
  box-shadow: 0px 1px 20px 1px rgba(69, 65, 78, 0.06); 
}
.timeline > li.timeline-inverted + li:not(.timeline-inverted) { margin-top: -60px; }
.timeline > li:not(.timeline-inverted) { padding-right: 90px; }
.timeline > li:not(.timeline-inverted) + li.timeline-inverted { margin-top: -60px; }
.timeline > li.timeline-inverted { padding-left: 90px; }
.timeline > li.timeline-inverted > .timeline-panel { float: right; }
.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto; 
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto; 
}
.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #eeeeee;
  border-right: 0 solid #eeeeee;
  border-bottom: 15px solid transparent;
  content: " "; 
}
.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #ffffff;
  border-right: 0 solid #ffffff;
  border-bottom: 14px solid transparent;
  content: " "; 
}
.timeline > li > .timeline-badge {
  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.8em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -25px;
  background-color: #999999;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%; 
}

.timeline-badge.default { background-color: #1a2035 !important; }
.timeline-badge.primary { background-color: #ff3030 !important; }
.timeline-badge.secondary { background-color: #0071e8 !important; }
.timeline-badge.success { background-color: #1bb73f !important; }
.timeline-badge.warning { background-color: #ff9d21 !important; }
.timeline-badge.danger { background-color: #dc3545 !important; }
.timeline-badge.info { background-color: #48abf7 !important; }
.timeline-title {
  font-size: 17px;
  margin-top: 0;
  color: inherit;
  font-weight: 400; 
}
.timeline-heading i {
  font-size: 22px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px; 
}
.timeline-body > p, .timeline-body > ul { margin-bottom: 0; }
.timeline-body > p + p { margin-top: 5px; }

/*      Google Maps      */
.full-screen-maps { height: 100vh !important; }

/*      jQVMap     */
.vmap {
  width: 100%;
  min-height: 265px; 
}
.vmap > svg {
  margin: auto;
  display: flex; 
}
.vmap > svg > g { transition: all ease-in-out .2s; }
.jqvmap-label, .jqvmap-pin { pointer-events: none; }
.jqvmap-label {
  position: absolute;
  display: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #292929;
  color: #ffffff;
  font-size: smaller;
  padding: 3px; 
}
.jqvmap-zoomin, .jqvmap-zoomout {
  position: absolute;
  left: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: #ff3030;
  color: #ffffff;
  cursor: pointer;
  line-height: 23px;
  height: 23px;
  width: 23px;
  text-align: center;
  font-size: 18px; 
}
.jqvmap-zoomin { top: 15px; }
.jqvmap-zoomout { top: 45px; }
.jqvmap-region { cursor: pointer; }
.jqvmap-ajax_response {
  width: 100%;
  height: 500px; 
}
.list-group .list-group-header {
  font-size: 12px;
  font-weight: 600;
  padding: .75rem 1rem; 
}
.list-group .list-group-item {
  display: flex;
  align-items: stretch;
  border-width: 1px 0;
  border-color: #ebecec; 
}
.list-group .list-group-item-figure {
  align-self: start;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #a9acb0; 
}
.list-group .list-group-item-body {
  flex: 1;
  min-width: 0;
  align-self: center;
  font-size: .875rem; 
}
.list-group .list-group-item-text {
  margin-bottom: 0;
  line-height: 1.25rem;
  color: #686f76; 
}
.list-group-file-item .list-group-item { padding: 0px; }
.list-group-file-item .list-group-item-figure { padding: .75rem 1rem; }
.list-group-file-item .list-group-item-body { padding: .75rem 0; }
.list-group-bordered .list-group-item { border: 1px solid #e3ebf6; }
.list-group-bordered .list-group-item.active {
  background: #ff3030;
  border-color: #ff3030; 
}
.list-group-lg .list-group-item {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; 
}
.selectgroup {
  display: -ms-inline-flexbox;
  display: inline-flex; 
}
.selectgroup-item {
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  font-weight: 400 !important; 
}
.selectgroup-item + .selectgroup-item { margin-left: -1px; }
.selectgroup-item:not(:first-child) .selectgroup-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; 
}
.selectgroup-item:not(:last-child) .selectgroup-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; 
}
.selectgroup-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0; 
}
.selectgroup-button {
  display: block;
  border: 1px solid rgba(0, 40, 100, 0.12);
  text-align: center;
  padding: 0.375rem 1rem;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  color: #9aa0ac;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  line-height: 1.5rem;
  min-width: 2.375rem; 
}
.selectgroup-button-icon {
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: 1rem; 
}

.selectgroup-input:checked + .selectgroup-button {
  border-color: #ff3030;
  z-index: 1;
  color: #ff3030;
  background: rgba(21, 114, 232, 0.15); 
}
.selectgroup-input:focus + .selectgroup-button {
  border-color: #ff3030;
  z-index: 2;
  color: #ff3030;
  box-shadow: 0 0 0 2px rgba(21, 114, 232, 0.25); 
}
.selectgroup-pills {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: start;
  align-items: flex-start; 
}
.selectgroup-pills .selectgroup-item {
  margin-right: .5rem;
  -ms-flex-positive: 0;
  flex-grow: 0; 
}
.selectgroup-pills .selectgroup-button { border-radius: 50px !important; }
.selectgroup.selectgroup-primary .selectgroup-input:checked + .selectgroup-button {
  border-color: #ff3030;
  color: #ff3030;
  background: rgba(21, 114, 232, 0.15); 
}
.selectgroup.selectgroup-primary .selectgroup-input:focus + .selectgroup-button {
  border-color: #ff3030;
  color: #ff3030;
  box-shadow: 0 0 0 2px rgba(21, 114, 232, 0.25); 
}
.selectgroup.selectgroup-secondary .selectgroup-input:checked + .selectgroup-button {
  border-color: #0071e8;
  color: #0071e8;
  background: rgba(104, 97, 206, 0.15); 
}
.selectgroup.selectgroup-secondary .selectgroup-input:focus + .selectgroup-button {
  border-color: #0071e8;
  color: #0071e8;
  box-shadow: 0 0 0 2px rgba(104, 97, 206, 0.25); 
}
.selectgroup.selectgroup-info .selectgroup-input:checked + .selectgroup-button {
  border-color: #48abf7;
  color: #48abf7;
  background: rgba(72, 171, 247, 0.15); 
}
.selectgroup.selectgroup-info .selectgroup-input:focus + .selectgroup-button {
  border-color: #48abf7;
  color: #48abf7;
  box-shadow: 0 0 0 2px rgba(72, 171, 247, 0.25); 
}
.selectgroup.selectgroup-success .selectgroup-input:checked + .selectgroup-button {
  border-color: #1bb73f;
  color: #1bb73f;
  background: rgba(49, 206, 54, 0.15); 
}
.selectgroup.selectgroup-success .selectgroup-input:focus + .selectgroup-button {
  border-color: #1bb73f;
  color: #1bb73f;
  box-shadow: 0 0 0 2px rgba(49, 206, 54, 0.25); 
}
.selectgroup.selectgroup-warning .selectgroup-input:checked + .selectgroup-button {
  border-color: #ff9d21;
  color: #ff9d21;
  background: rgba(255, 173, 70, 0.15); 
}
.selectgroup.selectgroup-warning .selectgroup-input:focus + .selectgroup-button {
  border-color: #ff9d21;
  color: #ff9d21;
  box-shadow: 0 0 0 2px rgba(255, 173, 70, 0.25); 
}
.selectgroup.selectgroup-danger .selectgroup-input:checked + .selectgroup-button {
  border-color: #dc3545;
  color: #dc3545;
  background: rgba(242, 89, 97, 0.15); 
}
.selectgroup.selectgroup-danger .selectgroup-input:focus + .selectgroup-button {
  border-color: #dc3545;
  color: #dc3545;
  box-shadow: 0 0 0 2px rgba(242, 89, 97, 0.25); 
}
.colorinput {
  margin: 0;
  position: relative;
  cursor: pointer; 
}
.colorinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0; 
}
.colorinput-color {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 3px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 
}
.colorinput-color:before {
  content: '';
  opacity: 0;
  position: absolute;
  top: .25rem;
  left: .25rem;
  height: 1.25rem;
  width: 1.25rem;
  transition: .3s opacity;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%; 
}
.colorinput-input:checked ~ .colorinput-color:before { opacity: 1; }
.colorinput-input:focus ~ .colorinput-color {
  border-color: #467fcf;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25); 
}
.imagecheck {
  margin: 0;
  position: relative;
  cursor: pointer; 
}
.imagecheck-input {
  position: absolute;
  z-index: -1;
  opacity: 0; 
}
.imagecheck-figure {
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  margin: 0;
  position: relative; 
}
.imagecheck-input:focus ~ .imagecheck-figure {
  border-color: #ff3030;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25); 
}
.imagecheck-input:checked ~ .imagecheck-figure { border-color: rgba(0, 40, 100, 0.24); }
.imagecheck-figure:before {
  content: '';
  position: absolute;
  top: .25rem;
  left: .25rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #ff3030 url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%;
  color: #fff;
  z-index: 1;
  border-radius: 3px;
  opacity: 0;
  transition: .3s opacity; 
}
.imagecheck-input:checked ~ .imagecheck-figure:before { opacity: 1; }
.imagecheck-image {
  max-width: 100%;
  opacity: .64;
  transition: .3s opacity; 
}
.imagecheck-image:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; 
}
.imagecheck-image:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; 
}

.imagecheck:hover .imagecheck-image { opacity: 1; }

.imagecheck-input:focus ~ .imagecheck-figure .imagecheck-image, .imagecheck-input:checked ~ .imagecheck-figure .imagecheck-image {
  opacity: 1; 
}
.imagecheck-caption {
  text-align: center;
  padding: .25rem .25rem;
  color: #9aa0ac;
  font-size: 0.875rem;
  transition: .3s color; 
}
.imagecheck:hover .imagecheck-caption { color: #495057; }

.imagecheck-input:focus ~ .imagecheck-figure .imagecheck-caption, .imagecheck-input:checked ~ .imagecheck-figure .imagecheck-caption {
  color: #495057; 
}
.loader, .is-loading:after {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: 3px solid #0071e8;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: 1s spin linear infinite; 
}
.loader-lg, .is-loading-lg:after {
  width: 2rem;
  height: 2rem;
  border-width: 5px; 
}
.loader-sm, .is-loading-sm:after {
  width: 1rem;
  height: 1rem;
  border-width: 2px; 
}
.is-loading {
  position: relative;
  color: transparent !important; 
}
.is-loading > * { opacity: 0.2 !important; }
.is-loading:after {
  position: absolute;
  top: calc(50% - 1.5rem/2);
  left: calc(50% - 1.5rem/2);
  content: ''; 
}

.is-loading-lg:after {
  top: calc(50% - 2rem/2);
  left: calc(50% - 2rem/2); 
}

.is-loading-sm:after {
  top: calc(50% - 1rem/2);
  left: calc(50% - 1rem/2); 
}

.btn-default.is-loading:after, .card-default.is-loading:after, .btn-primary.is-loading:after, .card-primary.is-loading:after, .btn-secondary.is-loading:after, .card-secondary.is-loading:after, .btn-info.is-loading:after, .card-info.is-loading:after, .btn-success.is-loading:after, .card-success.is-loading:after, .btn-warning.is-loading:after, .card-warning.is-loading:after, .btn-danger.is-loading:after, .card-danger.is-loading:after, .loader-default,
.is-loading-default:after, .loader-primary,
.is-loading-primary:after, .loader-secondary,
.is-loading-secondary:after, .loader-info,
.is-loading-info:after, .loader-success,
.is-loading-success:after, .loader-warning,
.is-loading-warning:after, .loader-danger,
.is-loading-danger:after {
  border-bottom-color: transparent !important; 
}

.btn-default.is-loading:after, .card-default.is-loading:after, .btn-primary.is-loading:after, .card-primary.is-loading:after, .btn-secondary.is-loading:after, .card-secondary.is-loading:after, .btn-info.is-loading:after, .card-info.is-loading:after, .btn-success.is-loading:after, .card-success.is-loading:after, .btn-warning.is-loading:after, .card-warning.is-loading:after, .btn-danger.is-loading:after, .card-danger.is-loading:after {
  border-color: #fff; 
}

.loader-default,
.is-loading-default:after { border-color: #1a2035; }

.loader-primary,
.is-loading-primary:after { border-color: #ff3030; }

.loader-secondary,
.is-loading-secondary:after { border-color: #0071e8; }

.loader-info,
.is-loading-info:after { border-color: #48abf7; }

.loader-success,
.is-loading-success:after { border-color: #1bb73f; }

.loader-warning,
.is-loading-warning:after { border-color: #ff9d21; }

.loader-danger,
.is-loading-danger:after { border-color: #dc3545; }

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); } 
}
/*      jQuery Ui     */
.ui-draggable-handle {
  -ms-touch-action: none;
  touch-action: none; 
}
.ui-helper-hidden { display: none; }

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; 
}
.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; 
}
.ui-helper-clearfix:after, .ui-helper-clearfix:before {
  content: "";
  display: table;
  border-collapse: collapse; 
}
.ui-helper-clearfix:after { clear: both; }
.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0); 
}
.ui-front { z-index: 100; }
.ui-state-disabled {
  cursor: default !important;
  pointer-events: none; 
}
.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; 
}
.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block; 
}
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
}
.ui-resizable { position: relative; }
.ui-resizable-handle {
  position: absolute;
  font-size: .1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none; 
}
.ui-resizable-autohide .ui-resizable-handle, .ui-resizable-disabled .ui-resizable-handle { display: none; }
.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0; 
}
.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0; 
}
.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%; 
}
.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%; 
}
.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px; 
}
.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px; 
}
.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px; 
}
.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px; 
}
.ui-selectable {
  -ms-touch-action: none;
  touch-action: none; 
}
.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dotted #000; 
}
.ui-sortable-handle {
  -ms-touch-action: none;
  touch-action: none; 
}
.ui-slider {
  position: relative;
  text-align: left;
  background: #ddd; 
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1em;
  height: 1em;
  cursor: default;
  -ms-touch-action: none;
  touch-action: none; 
}
.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0; 
}
.ui-slider.ui-state-disabled .ui-slider-handle, .ui-slider.ui-state-disabled .ui-slider-range { filter: inherit; }

.ui-slider-horizontal { height: .4em; }
.ui-slider-horizontal .ui-slider-handle {
  top: -.4em;
  margin-left: -.6em; 
}
.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%; 
}
.ui-slider-horizontal .ui-slider-range-min { left: 0; }
.ui-slider-horizontal .ui-slider-range-max { right: 0; }

.ui-slider-vertical {
  width: .8em;
  height: 100px; 
}
.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-left: 0;
  margin-bottom: -.6em; 
}
.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%; 
}
.ui-slider-vertical .ui-slider-range-min { bottom: 0; }
.ui-slider-vertical .ui-slider-range-max { top: 0; }

.ui-slider-handle {
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0, #f7f7f7 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ffffff), color-stop(100%, #f7f7f7));
  background: -webkit-linear-gradient(top, #ffffff 0, #f7f7f7 100%);
  background: -o-linear-gradient(top, #ffffff 0, #f7f7f7 100%);
  background: -ms-linear-gradient(top, #ffffff 0, #f7f7f7 100%);
  background: linear-gradient(to bottom, #ffffff 0, #f7f7f7 100%);
  border-radius: 50px;
  box-shadow: 0 1px 4px 0 #9191ab !important; 
}
.ui-slider-handle:focus {
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0, #f7f7f7 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ffffff), color-stop(100%, #f7f7f7));
  background: -webkit-linear-gradient(top, #ffffff 0, #f7f7f7 100%);
  background: -o-linear-gradient(top, #ffffff 0, #f7f7f7 100%);
  background: -ms-linear-gradient(top, #ffffff 0, #f7f7f7 100%);
  background: linear-gradient(to bottom, #ffffff 0, #f7f7f7 100%);
  border-radius: 50px;
  box-shadow: 0 1px 4px 0 #9191ab !important; 
}

/*      jQuery Scrollbar     */
.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative; 
}
.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  width: auto !important; 
}
.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0; 
}
.scroll-element {
  display: none;
  box-sizing: content-box; 
}
.scroll-element div { box-sizing: content-box; }
.scroll-element.scroll-x.scroll-scrollx_visible, .scroll-element.scroll-y.scroll-scrolly_visible { display: block; }
.scroll-element .scroll-arrow, .scroll-element .scroll-bar { cursor: default; }

.scroll-textarea {
  border: 1px solid #ccc;
  border-top-color: #999; 
}
.scroll-textarea > .scroll-content { overflow: hidden !important; }
.scroll-textarea > .scroll-content > textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: 0;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important; 
}
.scrollbar-inner > .scroll-element .scroll-element_outer, .scrollbar-outer > .scroll-element .scroll-element_outer { overflow: hidden; }
.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0; 
}
.scrollbar-inner > .scroll-element {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; 
}
.scrollbar-inner > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; 
}
.scrollbar-inner > .scroll-element.scroll-x {
  bottom: 2px;
  height: 7px;
  left: 0;
  width: 100%; 
}
.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size, .scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -12px; 
}
.scrollbar-inner > .scroll-element.scroll-y {
  height: 100%;
  right: 2px;
  top: 0;
  width: 7px; 
}
.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size, .scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -12px; 
}
.scrollbar-inner > .scroll-element .scroll-bar, .scrollbar-inner > .scroll-element .scroll-element_outer, .scrollbar-inner > .scroll-element .scroll-element_track {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; 
}
.scrollbar-inner > .scroll-element .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  opacity: .4; 
}
.scrollbar-inner > .scroll-element .scroll-element_track {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  opacity: .4;
  background-color: #e0e0e0; 
}
.scrollbar-inner > .scroll-element .scroll-bar { background-color: #c2c2c2; }
.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar, .scrollbar-inner > .scroll-element:hover .scroll-bar { background-color: #919191; }

.scrollbar-outer > .scroll-element {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
  background-color: #ffffff; 
}
.scrollbar-outer > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; 
}
.scrollbar-outer > .scroll-element.scroll-x {
  bottom: 0;
  height: 12px;
  left: 0;
  width: 100%; 
}
.scrollbar-outer > .scroll-element.scroll-y {
  height: 100%;
  right: 0;
  top: 0;
  width: 12px; 
}
.scrollbar-outer > .scroll-element.scroll-x .scroll-element_outer {
  height: 7px;
  top: 2px; 
}
.scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
  left: 2px;
  width: 7px; 
}
.scrollbar-outer > .scroll-element .scroll-element_track { background-color: #eee; }
.scrollbar-outer > .scroll-element .scroll-bar, .scrollbar-outer > .scroll-element .scroll-element_outer, .scrollbar-outer > .scroll-element .scroll-element_track {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; 
}
.scrollbar-outer > .scroll-element .scroll-bar { background-color: #d9d9d9; }
.scrollbar-outer > .scroll-element .scroll-bar:hover { background-color: #c2c2c2; }
.scrollbar-outer > .scroll-element.scroll-draggable .scroll-bar { background-color: #919191; }
.scrollbar-outer > .scroll-content.scroll-scrolly_visible {
  left: -12px;
  margin-left: 12px; 
}
.scrollbar-outer > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size, .scrollbar-outer > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -14px; 
}
.scrollbar-outer > .scroll-content.scroll-scrollx_visible {
  top: -12px;
  margin-top: 12px; 
}
.scrollbar-outer > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size, .scrollbar-outer > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -14px; 
}
.scrollbar-outer > .scroll-element.scroll-x .scroll-bar { min-width: 10px; }
.scrollbar-outer > .scroll-element.scroll-y .scroll-bar { min-height: 10px; }

.scrollbar-macosx > .scroll-element {
  background: 0 0;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; 
}
.scrollbar-macosx > .scroll-element div {
  background: 0 0;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; 
}
.scrollbar-macosx > .scroll-element .scroll-element_track { display: none; }
.scrollbar-macosx > .scroll-element .scroll-bar {
  background-color: #6C6E71;
  display: block;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  -ms-transition: opacity .2s linear;
  transition: opacity .2s linear; 
}
.scrollbar-macosx:hover > .scroll-element .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  opacity: .7; 
}
.scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  opacity: .7; 
}
.scrollbar-macosx > .scroll-element.scroll-x {
  bottom: 0;
  height: 0;
  left: 0;
  min-width: 100%;
  overflow: visible;
  width: 100%; 
}
.scrollbar-macosx > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 0;
  top: 0;
  width: 0; 
}
.scrollbar-macosx > .scroll-element.scroll-x .scroll-bar {
  height: 7px;
  min-width: 10px;
  top: -9px; 
}
.scrollbar-macosx > .scroll-element.scroll-y .scroll-bar {
  left: -9px;
  min-height: 10px;
  width: 7px; 
}
.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer { left: 2px; }
.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size { left: -4px; }
.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer { top: 2px; }
.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size { top: -4px; }
.scrollbar-macosx > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size { left: -11px; }
.scrollbar-macosx > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size { top: -11px; }

.scrollbar-light > .scroll-element {
  border: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  z-index: 10;
  background-color: #ffffff; 
}
.scrollbar-light > .scroll-element div {
  border: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  z-index: 10;
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; 
}
.scrollbar-light > .scroll-element .scroll-element_outer {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; 
}
.scrollbar-light > .scroll-element .scroll-element_size {
  background: -moz-linear-gradient(left, #dbdbdb 0, #e8e8e8 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0, #dbdbdb), color-stop(100%, #e8e8e8));
  background: -webkit-linear-gradient(left, #dbdbdb 0, #e8e8e8 100%);
  background: -o-linear-gradient(left, #dbdbdb 0, #e8e8e8 100%);
  background: -ms-linear-gradient(left, #dbdbdb 0, #e8e8e8 100%);
  background: linear-gradient(to right, #dbdbdb 0, #e8e8e8 100%);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; 
}
.scrollbar-light > .scroll-element.scroll-x {
  bottom: 0;
  height: 17px;
  left: 0;
  min-width: 100%;
  width: 100%; 
}
.scrollbar-light > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 0;
  top: 0;
  width: 17px; 
}
.scrollbar-light > .scroll-element .scroll-bar {
  background: -moz-linear-gradient(left, #fefefe 0, #f5f5f5 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0, #fefefe), color-stop(100%, #f5f5f5));
  background: -webkit-linear-gradient(left, #fefefe 0, #f5f5f5 100%);
  background: -o-linear-gradient(left, #fefefe 0, #f5f5f5 100%);
  background: -ms-linear-gradient(left, #fefefe 0, #f5f5f5 100%);
  background: linear-gradient(to right, #fefefe 0, #f5f5f5 100%);
  border: 1px solid #dbdbdb;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; 
}
.scrollbar-light > .scroll-content.scroll-scrolly_visible {
  left: -17px;
  margin-left: 17px; 
}
.scrollbar-light > .scroll-content.scroll-scrollx_visible {
  top: -17px;
  margin-top: 17px; 
}
.scrollbar-light > .scroll-element.scroll-x .scroll-bar {
  height: 10px;
  min-width: 10px;
  top: 0; 
}
.scrollbar-light > .scroll-element.scroll-y .scroll-bar {
  left: 0;
  min-height: 10px;
  width: 10px; 
}
.scrollbar-light > .scroll-element.scroll-x .scroll-element_outer {
  height: 12px;
  left: 2px;
  top: 2px; 
}
.scrollbar-light > .scroll-element.scroll-x .scroll-element_size { left: -4px; }
.scrollbar-light > .scroll-element.scroll-y .scroll-element_outer {
  left: 2px;
  top: 2px;
  width: 12px; 
}
.scrollbar-light > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size, .scrollbar-light > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -19px; 
}
.scrollbar-light > .scroll-element.scroll-y .scroll-element_size {
  top: -4px; 
}
.scrollbar-light > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size, .scrollbar-light > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -19px; 
}

.scrollbar-rail > .scroll-element {
  border: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  z-index: 10;
  background-color: #ffffff; 
}
.scrollbar-rail > .scroll-element div {
  border: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  z-index: 10;
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; 
}
.scrollbar-rail > .scroll-element .scroll-element_size {
  background-color: #999;
  background-color: rgba(0, 0, 0, 0.3); 
}
.scrollbar-rail > .scroll-element .scroll-element_outer:hover .scroll-element_size {
  background-color: #666;
  background-color: rgba(0, 0, 0, 0.5); 
}
.scrollbar-rail > .scroll-element.scroll-x {
  bottom: 0;
  height: 12px;
  left: 0;
  min-width: 100%;
  padding: 3px 0 2px;
  width: 100%; 
}
.scrollbar-rail > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  padding: 0 2px 0 3px;
  right: 0;
  top: 0;
  width: 12px; 
}
.scrollbar-rail > .scroll-element .scroll-bar {
  background-color: #d0b9a0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); 
}
.scrollbar-rail > .scroll-element .scroll-element_outer:hover .scroll-bar { box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6); }
.scrollbar-rail > .scroll-content.scroll-scrolly_visible {
  left: -17px;
  margin-left: 17px; 
}
.scrollbar-rail > .scroll-content.scroll-scrollx_visible {
  margin-top: 17px;
  top: -17px; 
}
.scrollbar-rail > .scroll-element.scroll-x .scroll-bar {
  height: 10px;
  min-width: 10px;
  top: 1px; 
}
.scrollbar-rail > .scroll-element.scroll-y .scroll-bar {
  left: 1px;
  min-height: 10px;
  width: 10px; 
}
.scrollbar-rail > .scroll-element.scroll-x .scroll-element_outer {
  height: 15px;
  left: 5px; 
}
.scrollbar-rail > .scroll-element.scroll-x .scroll-element_size {
  height: 2px;
  left: -10px;
  top: 5px; 
}
.scrollbar-rail > .scroll-element.scroll-y .scroll-element_outer {
  top: 5px;
  width: 15px; 
}
.scrollbar-rail > .scroll-element.scroll-y .scroll-element_size {
  left: 5px;
  top: -10px;
  width: 2px; 
}
.scrollbar-rail > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size, .scrollbar-rail > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -25px; 
}
.scrollbar-rail > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size, .scrollbar-rail > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -25px; 
}
.scrollbar-dynamic > .scroll-element {
  background: 0 0;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; 
}
.scrollbar-dynamic > .scroll-element div {
  background: 0 0;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; 
}
.scrollbar-dynamic > .scroll-element.scroll-x {
  bottom: 2px;
  height: 7px;
  left: 0;
  min-width: 100%;
  width: 100%; 
}
.scrollbar-dynamic > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 2px;
  top: 0;
  width: 7px; 
}
.scrollbar-dynamic > .scroll-element .scroll-element_outer {
  opacity: .3;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px; 
}
.scrollbar-dynamic > .scroll-element .scroll-element_size {
  background-color: #ccc;
  opacity: 0;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  -o-transition: opacity .2s;
  -ms-transition: opacity .2s;
  transition: opacity .2s; 
}
.scrollbar-dynamic > .scroll-element .scroll-bar {
  background-color: #6c6e71;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; 
}
.scrollbar-dynamic > .scroll-element.scroll-x .scroll-bar {
  bottom: 0;
  height: 7px;
  min-width: 24px;
  top: auto; 
}
.scrollbar-dynamic > .scroll-element.scroll-y .scroll-bar {
  left: auto;
  min-height: 24px;
  right: 0;
  width: 7px; 
}
.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_outer {
  bottom: 0;
  top: auto;
  left: 2px;
  -webkit-transition: height .2s;
  -moz-transition: height .2s;
  -o-transition: height .2s;
  -ms-transition: height .2s;
  transition: height .2s; 
}
.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_outer {
  left: auto;
  right: 0;
  top: 2px;
  -webkit-transition: width .2s;
  -moz-transition: width .2s;
  -o-transition: width .2s;
  -ms-transition: width .2s;
  transition: width .2s; 
}
.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_size { left: -4px; }
.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_size { top: -4px; }
.scrollbar-dynamic > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size { left: -11px; }
.scrollbar-dynamic > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size { top: -11px; }
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer, .scrollbar-dynamic > .scroll-element:hover .scroll-element_outer {
  overflow: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  opacity: .7; 
}
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-element_size, .scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-element_size {
  opacity: 1; 
}
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-bar, .scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-bar {
  height: 100%;
  width: 100%;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px; 
}
.scrollbar-dynamic > .scroll-element.scroll-x.scroll-draggable .scroll-element_outer, .scrollbar-dynamic > .scroll-element.scroll-x:hover .scroll-element_outer {
  height: 20px;
  min-height: 7px; 
}
.scrollbar-dynamic > .scroll-element.scroll-y.scroll-draggable .scroll-element_outer, .scrollbar-dynamic > .scroll-element.scroll-y:hover .scroll-element_outer {
  min-width: 7px;
  width: 20px; 
}
.scrollbar-chrome > .scroll-element {
  border: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  z-index: 10;
  background-color: #ffffff; 
}
.scrollbar-chrome > .scroll-element div {
  border: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  z-index: 10;
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; 
}
.scrollbar-chrome > .scroll-element .scroll-element_track {
  background: #f1f1f1;
  border: 1px solid #dbdbdb; 
}
.scrollbar-chrome > .scroll-element.scroll-x {
  bottom: 0;
  height: 16px;
  left: 0;
  min-width: 100%;
  width: 100%; 
}
.scrollbar-chrome > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 0;
  top: 0;
  width: 16px; 
}
.scrollbar-chrome > .scroll-element .scroll-bar {
  background-color: #d9d9d9;
  border: 1px solid #bdbdbd;
  cursor: default;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; 
}
.scrollbar-chrome > .scroll-element .scroll-bar:hover {
  background-color: #c2c2c2;
  border-color: #a9a9a9; 
}
.scrollbar-chrome > .scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191;
  border-color: #7e7e7e; 
}
.scrollbar-chrome > .scroll-content.scroll-scrolly_visible {
  left: -16px;
  margin-left: 16px; 
}
.scrollbar-chrome > .scroll-content.scroll-scrollx_visible {
  top: -16px;
  margin-top: 16px; 
}
.scrollbar-chrome > .scroll-element.scroll-x .scroll-bar {
  height: 5px;
  min-width: 10px;
  top: 3px; 
}
.scrollbar-chrome > .scroll-element.scroll-y .scroll-bar {
  left: 3px;
  min-height: 10px;
  width: 5px; 
}
.scrollbar-chrome > .scroll-element.scroll-x .scroll-element_outer { border-left: 1px solid #dbdbdb; }
.scrollbar-chrome > .scroll-element.scroll-x .scroll-element_track {
  height: 14px;
  left: -3px; 
}
.scrollbar-chrome > .scroll-element.scroll-x .scroll-element_size {
  height: 14px;
  left: -4px; 
}
.scrollbar-chrome > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size, .scrollbar-chrome > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -19px; 
}
.scrollbar-chrome > .scroll-element.scroll-y .scroll-element_outer { border-top: 1px solid #dbdbdb; }
.scrollbar-chrome > .scroll-element.scroll-y .scroll-element_track {
  top: -3px;
  width: 14px; 
}
.scrollbar-chrome > .scroll-element.scroll-y .scroll-element_size {
  top: -4px;
  width: 14px; 
}
.scrollbar-chrome > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size, .scrollbar-chrome > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -19px; 
}

/*      Datatable     */
table.dataTable {
  clear: both;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  max-width: none !important;
  border-collapse: separate !important; 
}
table.dataTable td, table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box; 
}
table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty { text-align: center; }
table.dataTable.nowrap th, table.dataTable.nowrap td { white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap; 
}
div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block; 
}
div.dataTables_wrapper div.dataTables_filter { text-align: right; }
div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left; 
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto; 
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap; 
}
div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right; 
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end; 
}
.page-link {
    width: 35px;
    height: 35px;
    line-height: 33px;
    padding: 0;
    text-align: center;
}
.page-link:hover { color: #ff3030; }
.previous .page-link, .next .page-link {
    width: auto;
    padding: 0 .75rem;
    height: 35px;
}
.page-item.active .page-link {
  background-color: #ff3030;
  border-color: #ff3030;
}
div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
   }

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
  padding-right: 30px; 
}
table.dataTable thead > tr > th:active, table.dataTable thead > tr > td:active { outline: none; }
table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative; 
}
table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.5; 
}
table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
  right: 9px;
  content: "\2191";
  font-size: 15px; 
}
table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  right: 3px;
  content: "\2193";
  font-size: 15px; 
}
table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:after { opacity: 1; }
table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:after { opacity: 0; }

div.dataTables_scrollHead table.dataTable { margin-bottom: 0 !important; }
div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important; 
}
div.dataTables_scrollBody table thead .sorting:after, div.dataTables_scrollBody table thead .sorting_asc:after, div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none; 
}
div.dataTables_scrollBody table tbody tr:first-child th, div.dataTables_scrollBody table tbody tr:first-child td { border-top: none; }
div.dataTables_scrollFoot > .dataTables_scrollFootInner { box-sizing: content-box; }
div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none; 
}

@media screen and (max-width: 767px) {
  .dataTables_wrapper.container-fluid { padding: 0; }
  div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
    margin-bottom: 10px; 
  }
  div.dataTables_wrapper div.dataTables_filter { text-align: left; }
  div.dataTables_wrapper div div.dataTables_paginate ul.pagination {
    justify-content: start !important; 
  }
  div.dataTables_wrapper div div.dataTables_paginate ul.pagination li { margin-bottom: 10px; }
  div.dataTables_wrapper div div.dataTables_paginate ul.pagination li a { font-size: 11px; } 
}

table.dataTable.table-sm > thead > tr > th { padding-right: 20px; }
table.dataTable.table-sm .sorting:before, table.dataTable.table-sm .sorting_asc:before, table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em; 
}
table.dataTable.table-sm .sorting:after, table.dataTable.table-sm .sorting_asc:after, table.dataTable.table-sm .sorting_desc:after {
  top: 5px; 
}
table.table-bordered.dataTable th, table.table-bordered.dataTable td { border-left-width: 0; }
table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable td:last-child { border-right-width: 0; }
table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td { border-bottom-width: 0; }

div.dataTables_scrollHead table.table-bordered { border-bottom-width: 0; }
div.table-responsive > div.dataTables_wrapper > div.row { margin: 0; }
div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child, div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
  padding-left: 0;
  padding-right: 0; 
}

.example .example-preview + .example-code {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.example .example-code {
    padding: 0;
    position: relative;
    background-color: #F3F6F9;
    border-radius: 0.42rem;
        border-top-left-radius: 0.42rem;
        border-top-right-radius: 0.42rem;
}
.example .example-preview + .example-code .example-copy {
    top: 0rem;
    height: 25px;
}
.example .example-code .example-copy {
    position: absolute;
    top: 0.25rem;
    right: 0.75rem;
}
.example .example-toggle, .example .example-copy {
    height: 34px;
    width: 34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 0.25rem;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    cursor: pointer;
    font-size: 1.15rem;
    color: #B5B5C3;
}
.example .example-copy::after {
    font-family: 'Font Awesome 5 Regular';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    /* font-smoothing: antialiased; */
    content: "\f0c5";
}

  /*-------- Login Register Css Style ----------- */
  .main-form .control-label {
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
  }
  .input-box {
    position: relative;
    
  }
  .input-box .input-icon {
    position: absolute;
    left: 0;
    top: 50%;
    width: 40px;
    text-align: center;
    border-right: 1px solid #e1e1e1;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .input-box .input-icon.eye-icon {
    right: 0;
    left: auto;
    top: 50%;
    cursor: pointer;
    border-right: none;
    padding-right: 0;
  }
  .login-form .main-form input {
    padding-left: 50px;
  }
  .login-form .form-group, .login-form .form-check, .register-form .form-group  {
    padding: 10px 0;
  }
  .form-section .checkbox + label::before {
    margin-top: 0;
  }
  .form-section .checkbox:checked + label::after {
    margin-top: 2px;
  }
  .forgot-password {
    font-weight: 500;
    display: inline-block;
  }
  .different-login {
    position: relative;
  }
  .different-login span {
    background-color: #fff;
    padding: 0 15px;
    position: relative;
    text-transform: uppercase;
  }
  .different-login::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid #ddd;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .another-login ul li {
    display: inline-block;
  }
  .another-login .btn{
    color: #ffffff;
  }
  .btn-facebook {
    background: #3d5b99;
  }
  .btn-facebook:hover {
    background: #304a82;
  }
  .btn-google {
    background: #d55344;
  }
  .btn-google:hover {
    background: #d0311e;
  }
  /* .login-register-2 .wrapper {
    background: url(../../assets/img/login-bg.jpg) no-repeat scroll center;
  } */
  .login-register-2 .main-panel {
    width: 100%;
    float: none;
  }
  .login-register-2 .main-panel > .content {
    height: 100%;
    margin: 0;
    min-height: 100%;
    overflow: visible;
  }
  /*-------- Login Register Css Style End -------- */
/*     Responsive     */
@media only screen and (min-width: 1200px) and (max-width: 1529px) {
  .statistics .row .col-xl-6 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  /*.form-control { width: auto; }*/
  div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate { margin-bottom: 20px; }
}
@media screen and (max-width: 576px) {
  .row-card-no-pd [class*=col-] .card:before {
    width: calc(100% - 30px) !important;
    right: 15px !important;
    height: 1px !important; 
  }
  .row-card-no-pd [class*=col-]:first-child .card:before { display: none !important; } 
  .breadcrumb { margin-left: 0; }
}


@media screen and (min-width: 991px) {
  .main-panel.full-height > .content, .main-panel.full-height > .content-full { margin-top: 0; }
  .main-panel.full-height .navbar-header { min-height: 62px; }
  .toggle-nav-search { display: none; }
  #search-nav { display: block !important; }
  .sidebar .scroll-element {
    opacity: 0;
    transition: all .2s; 
  }
  .sidebar:hover .scroll-element { opacity: 1; }
}

@media screen and (max-width: 991px) {

  .sidebar {
    position: fixed;
    left: 0 !important;
    right: 0;
    -webkit-transform: translate3d(-270px, 0, 0);
    -moz-transform: translate3d(-270px, 0, 0);
    -o-transform: translate3d(-270px, 0, 0);
    -ms-transform: translate3d(-270px, 0, 0);
    transform: translate3d(-270px, 0, 0) !important;
    transition: all .5s;
    margin-top: 0px; 
  }
  .sidebar:before { background: none; }
  .nav_open .sidebar {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0) !important;
    border-right: 1px solid #f1f1f1; 
  }
  .sidebar .sidebar-wrapper { padding-top: 0px; }
  .sidebar .sidebar-wrapper .sidebar-content { padding-top: 0px !important; }
  .sidebar .sidebar-wrapper .scroll-element.scroll-y { top: 0px !important; }

  .nav_open { overflow: hidden !important; }
  .nav_open .wrapper { overflow-x: hidden; }
  .nav_open .main-panel, .nav_open .main-header {
    -webkit-transform: translate3d(250px, 0, 0);
    -moz-transform: translate3d(250px, 0, 0);
    -o-transform: translate3d(250px, 0, 0);
    -ms-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0) !important; 
  }
  .navbar .navbar-nav .nav-item .nav-link {
    background: #ff3030 !important;
  }
  .quick_sidebar_open .quick-sidebar { width: 350px; }

  .main-header { transition: all .5s; }

  #search-nav { margin: 0 auto !important; }

  .main-panel {
    height: fit-content;
    width: 100%;
    transition: all .5s; 
  }
  .main-panel .page-header .dropdown-menu:after { right: 16px !important; }

  /* .page-inner {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; 
  } */

  .page-sidebar { background: #fff; }

  .logo-header {
    display: flex;
    width: 100% !important;
    text-align: left;
    position: relative;
    padding-left: 15px;
    padding-right: 15px; 
  }
  .logo-header .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); 
  }
  .logo-header .navbar-toggler {
    height: 100%;
    margin-left: 0px !important;
    opacity: 1;
    display: block;
    order: 1; 
  }
  .logo-header .more {
    opacity: 1;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    line-height: 56px;
    order: 3;
    width: unset;
    margin-left: auto; 
  }
  .logo-header .navbar-brand {
    position: unset !important;
    margin-right: 0px; 
  }
  .nav-search {
    width: 100%;
    margin-right: 0 !important; 
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.15) !important
  }
  .navbar-header {
    position: absolute;
    width: 100%;
    transform: translate3d(0, -200px, 0) !important;
    transition: all .5s; 
  }
  .topbar_open .navbar-header {
    transform: translate3d(0, 61px, 0) !important;
    padding: 12px; 
  }
  .main-header .navbar-header {
    min-height: 52px;
    margin-top: 0;
    background: #1b2839;
  }
  .topbar_open .navbar-header .navbar-nav > .nav-item .nav-link i { font-size: 19px; }
  .topbar_open .navbar-header .navbar-nav > .nav-item:last-child .nav-link { padding: 0px !important; }
  .topbar_open .navbar-header .navbar-nav > .nav-item:last-child .quick-sidebar-toggler { padding-left: 5px !important; }
  .topbar_open .toggle-nav-search { display: list-item; }
  .topbar_open #search-nav {
    text-align: center;
    width: 100%;
    padding: 10px 15px 0px;
    order: 1; 
  }
  .topbar_open .main-panel { transform: translate3d(0, 62px, 0) !important; }
  .topbar_open > .content { margin-top: 0px !important; }

  .nav_open.topbar_open .main-panel { transform: translate3d(250px, 60px, 0) !important; }

  .navbar-header .navbar-nav {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin-left: 0px !important;
    position: relative; 
  }
  .navbar-header .navbar-nav .dropdown { position: unset; }
  .navbar-header .navbar-nav .dropdown-menu {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 280px; 
  }

  .profile-pic span { display: none; }
  .nav-toggle { display: none; }
  .page-title { font-size: 18px; }
  .card .card-title { font-size: 18px; }
  .mail-wrapper .mail-option .email-filters-left { width: 50%; }
  .mail-wrapper .mail-option .email-filters-left .btn-group { margin-bottom: 10px; }

  /* Dropzone */
  .dropzone { padding: 20px 15px !important; }
  .dropzone .dz-message .message { font-size: 23px; }
  .dropzone .dz-message .note { font-size: 15px; }
  .total{
    margin-left: 400px !important;
  } 
}

@media screen and (min-width: 856px) {
  .mail-wrapper .aside-nav { display: block !important; } 
}

@media screen and (max-width: 856px) {
  .mail-wrapper { flex-direction: column; }
  .mail-wrapper .page-aside {
    width: 100%;
    height: unset;
    min-height: unset;
    border-bottom: 1px solid #eee;
    border-left: 0;
    border-right: 0;
    background: transparent;
    padding-top: 0px;
    padding-bottom: 0px; 
  }
  .mail-wrapper .page-aside .aside-header {
    padding-top: 25px;
    padding-bottom: 25px;
    background: #f1f1f1; 
  }
  .mail-wrapper .page-aside .aside-nav {
    background: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px; 
  }
  .mail-wrapper .mail-content { width: 100%; }
  .mail-wrapper .mail-content .inbox-head {
    flex-direction: column;
    align-items: left; 
  }
  .mail-wrapper .mail-content .inbox-head h3 { font-size: 18px; }
  .mail-wrapper .mail-content .inbox-head form {
    margin-left: 0px !important;
    margin-top: 15px; 
  }
  .mail-wrapper .mail-content .email-head h3 { font-size: 18px; }
  .mail-wrapper .mail-content .email-compose-fields { padding: 20px 15px; }
  .mail-wrapper .mail-option { flex-direction: column; }
  .mail-wrapper .mail-option .email-filters-left {
    width: 100%;
    margin-bottom: 10px; 
  }
  .mail-wrapper .toggle-email-nav { display: inline-block !important; }
  .mail-wrapper .table-inbox tr td .badge {
    margin-top: 5px;
    float: left; 
  }
  .total{
    margin-left: 250px !important;
  } 
}
@media screen and (max-width: 767px) {
  .wizard-container {
    margin-left: 15px;
    margin-right: 15px; 
  }
  .main-panel .page-header {
    flex-direction: column;
    align-items: normal;
    position: relative;
    min-height: 43px;
    justify-content: center; 
  }
  .main-panel .page-header .breadcrumbs {
    margin-left: 0px;
    padding-top: 15px;
    padding-left: 5px;
    padding-bottom: 0px;
    border-left: 0px; 
  }
  .main-panel .page-header .btn-group-page-header {
    position: absolute;
    right: 0px; 
  }
  .footer .container-fluid { flex-direction: column; }
  .footer .container-fluid .copyright {
    margin-left: 0 !important;
    margin-top: 10px;
    margin-bottom: 15px; 
   
  } 
  
  .footer{
    display: flex;
    flex-direction: column !important;
    height:auto !important;
    margin: 0px;
    padding: 0px;
    height: 10% !important;
    
  }
  .input-box .control-label {
    text-align: left;
  }
  .total{
    margin-left: 250px !important;
  }
}
 @media only screen and (min-width: 576px) and (max-width: 619px) {
  .statistics .row .col-sm-6 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media screen and (max-width: 576px) {

  .footer{
    display: flex;
    flex-direction: column !important;
    height:auto !important;
    height: 10% !important;
    
  }
  .total{
    margin-left: 100px !important;
  }
  
  #chart-container { min-height: 250px; }
  .form-check-inline {
    display: flex;
    flex-direction: column;
    align-items: left; 
  }
  #calendar .fc-toolbar {
    display: flex;
    flex-direction: column; 
  }
  #calendar .fc-toolbar .fc-left, #calendar .fc-toolbar .fc-right, #calendar .fc-toolbar .fc-center {
    margin: auto;
    margin-bottom: 15px; 
  }
  #calendar .fc-toolbar .fc-left { order: 1; }
  #calendar .fc-toolbar .fc-right { order: 3; }
  #calendar .fc-toolbar .fc-center { order: 2; }

  .conversations .conversations-body { padding: 1.5rem 1rem; } 
  .nav-pills .nav-link { padding: 6px 10px; }
  .btn {
    padding: 6px 12px;
  }
 
}
@media screen and (max-width: 350px) {
  .quick_sidebar_open .quick-sidebar {
    width: 100%;
    padding: 20px; 
  } 
  .card .card-body, .card-light .card-body {
    padding: 10px;
  }
  .card .card-title {
    font-size: 17px;
  }
  
}
.row{
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}


@media (min-width: 576px) {
  .col-sm-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
  }
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, 
.col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, 
.col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, 
/* .col-md-3, */ .col-md-4, /* .col-md-5, */ .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, 
.col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, 
.col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, 
.col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
*, ::after, ::before {
  box-sizing: border-box;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}
/* .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
} */
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;}
  
  .icon-options-vertical{
    font-family: simple-line-icons;
   
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;}


    .abcd{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 750px;
      /* align-items: center; */
      padding-top: 7px;
       
    }
   .offcanvas{
    background-color:#1b2839;
    height: 100vh;
    font-weight: 200;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    letter-spacing: 0.5px;
    width: 55% !important;
  }
  
   .side{
    display: flex;
    flex-direction: row;
   } 
   .sidepage{
    width: 100%;
    padding: 10px 10px;
   }
   .offcanvas-title{
    color: #ffffff;
   } 
   /* styling for side bar icons */
   .ib{
    color: #ffffff;
   }
   .btn-close{
    color: #ffffff !important;
    background-color: #97a2b1;
   }
   .sub-item{
    color: #ffffff;
   }
   /* styling for save and back button */
   .sbbt{
    display: flex;
    justify-content: space-between;
   }
  .main-panel{
    overflow: hidden !important;
  }
  .total{
    margin-left:575px;
    font-size: medium;
  }
  .member-image{
    width: "50px"!important;
    height: "50px"!important;
  }

  /* side bar nav header */
  .inline-container {
    display: flex;
    align-items: center;
  }
  
  .inline-container img {
    width: 65px;
    height: 55px;
    margin-right: 4px;
    padding-top: 8px;
  }
  
  .inline-container h4 {
    color: white;
    margin: 0;
    padding-top: 10px;
  }
  th{
    background-color:darkslateblue !important;
    border-radius: 1px;
    color: white !important;
  }

  /* product list page table scroll bar */

  .table-wrapper {
  overflow-x: auto;
  white-space: nowrap; /* This ensures the content stays in one line and forces horizontal scroll */
}

.table-wrapper::-webkit-scrollbar {
  height: 6px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* ---------------------------------------------- */
  
.demo{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  justify-content: space-between;
}
.demo12{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 10px;
  padding-right: 20px;
  margin-left: 550px;
  
}
.btn12{
  background-color:darkslateblue !important ;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  min-width: 250px;
  text-align: center;
  opacity: 5;
  border: none;
  
}
.sample{
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-left: 830px;
  padding-top: 10px;
  padding-right: 10px;
  width: 100%;
}

.sample1{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 850px;
  padding-top: 10px;
  padding-right: 0px;
  width: 100%;
}

.demo123{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 10px;
  padding-right: 20px;
}
input[type=text]{
  width: 100%;
  box-sizing: border-box;
  padding: 12px 10px;
  background-color:white !important;
  outline: none;
  border: 1px solid lightgray !important;
  color: grey !important;
  border-radius: 5px;
  margin: 5px;
  font-weight: lighter !important;
}
.btn123{
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  background-color:darkslateblue !important ;
  color: white;
  text-align: center !important;
  border-radius: 5px;
  padding: 10px 20px;
  min-width: 150px;
  font-size: 14px;
  opacity: 5;
  border: none;
}
.srcbt{
  background-color:darkslateblue !important ;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  opacity: 5;
  border: none;
}
/* .nav123{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
} */

.main-header123 {
  background: #ffffff;
  min-height: 60px;
  width: 70%;
  margin-left: 350px;
 
  margin-top: 25px;
  position: fixed;
  z-index: 1001;
  box-shadow: 0px 0px 5px rgba(18, 23, 39, 0.5); 
}
.dpbutton{
  color: #000 !important;
}

/* In App.css */

/* Hide menu button on large screens */
@media (min-width: 768px ) {
  .nav-toggle  {
    display: block !important;
    color:#000 !important;
    
  }
}

/* Show menu button on small screens */
@media (max-width: 767px) {
  .nav-toggle {
    display: block !important;
    padding-top: 30px; 
    padding-left: 20px;
    font-size: large;
  }
  
}

/* Sidebar hidden by default on small screens */
@media (max-width: 767px) {
  .sidebar {
    transform: translateX(-250px) !important;
    transition: transform 0.3s ease !important;
  }
  .sidebar.active {
    transform: translateX(0) !important;
  }
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.sidebar-wrapper::-webkit-scrollbar {
  width: 0px !important;
  background: transparent !important; /* Optional: Just to show that we are using a different background */
}

/* Hide scrollbar for IE, Edge, and Firefox */
.sidebar-wrapper {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}


.addUser {
  background-color: white;
  width: 25%;
  margin: 50px auto;
  /* margin in top and bottom will be 50px  */
  /* left and right auto  */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.065);

  /* horizon offset vertical offset blur offset  */
  /* opacity of shadow */
  /* red, green, blue, and (opacity ). */
}
.addUserForm .inputGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.addUserForm .inputGroup label {
  margin-top: 10px;
}

.addUserForm .inputGroup input {
  margin-top: 5px;
  padding: 10px;
}

.inputGroup button {
  margin-top: 10px;
}
.login {
  margin-top: 30px;
  text-align: center;
}

.login .btn.btn-success {
  width: 50%;
}

.addUser h3 {
  text-align: center;
  font-weight: bold;
  color: darkcyan;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}

.addUser {
  background-color: white;
  width: 25%;
  margin: 50px auto;
  /* margin in top and bottom will be 50px  */
  /* left and right auto  */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.065);

  /* horizon offset vertical offset blur offset  */
  /* opacity of shadow */
  /* red, green, blue, and (opacity ). */
}
.addUserForm .inputGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.addUserForm .inputGroup label {
  margin-top: 10px;
}

.addUserForm .inputGroup input {
  margin-top: 5px;
  padding: 10px;
}

.inputGroup button {
  margin-top: 10px;
}
.login {
  margin-top: 30px;
  text-align: center;
}

.login .btn.btn-primary {
  width: 50%;
}

.addUser h3 {
  text-align: center;
  font-weight: bold;
  color: darkcyan;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}


/* banner page css */

/* banner page upload button style */

.upload-banner{
  background-color: darkslateblue;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  display: flex;
  margin-left: 150px;
  margin-top: 20px;

}
.bannerform{
  margin-left: -140px !important;
}
/* Adjust the spacing and alignment of elements */
.upimage {
  display: flex;
  align-items: center;

}

.upimage .control-label {
  margin-bottom: 0; /* Remove bottom margin for proper alignment */
}

.upimage .form-control {
  width: auto; /* Ensure the input field does not take full width */
  margin-right: 10px; /* Add some spacing between the input and the button */
}

.upload-banner {
  white-space: nowrap; /* Ensure the button text does not wrap */
  
}

/* Adjust the form elements on smaller screens */
@media (max-width: 768px) {
  .upimage {
    flex-direction: column;
    align-items: flex-start;
  }

  .upimage .form-control,
  .upload-banner {
    width: 100%; /* Make input and button full width on small screens */
    margin-right: 0;
    margin-bottom: 10px; /* Add space between input and button on small screens */
  }
}
.bantabletitle{
  display: flex;
  justify-content: center;
  color:grey;
  padding-top: 20px;
}


