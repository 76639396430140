.image-input {
    display: none; /* Hide the file input */
  }
  
  .image-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    border-radius: 50%;
    overflow: hidden;
    background-color: #f0f0f0; /* Optional: background color */
    margin-top: 10px;
  }
  
  .image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .btn {
    margin-top: 10px;
  }
  
  .btncolor{
    color: #495057 !important;
  }