/* style.css */

/* Base styles */
.btn12{
    background-color:darkslateblue !important ;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    opacity: 5;
    border: none;
    
  }
  
  /* Media Queries */
  
  /* Mobile Devices */
  @media (max-width: 576px) {
    .card-body {
      padding: 10px;
    }
  
    .table th, .table td {
      padding: 8px;
      font-size: 14px;
    }
  
    .btn12 {
      padding: 8px 16px;
      font-size: 14px;
    }
  
    .panel-header {
      text-align: center;
    }
  }
  
  /* Tablets */
  @media (min-width: 576px) and (max-width: 768px) {
    .card-body {
      padding: 15px;
    }
  
    .table th, .table td {
      padding: 10px;
      font-size: 15px;
    }
  
    .btn12 {
      padding: 9px 18px;
      font-size: 15px;
    }
  
    .panel-header {
      text-align: center;
    }
  }
  
  /* Desktop Devices */
  @media (min-width: 768px) {
    .card-body {
      padding: 20px;
    }
  
    .table th, .table td {
      padding: 12px;
      font-size: 16px;
    }
  
    .btn12 {
      padding: 10px 20px;
      font-size: 16px;
    }
  }
  
  